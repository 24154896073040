import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Box, Card, Divider, Grid, Typography } from '@material-ui/core';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { ThemeProvider } from '@material-ui/core/styles';
import { TextField, SvgIcon } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import { motion } from 'framer-motion';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import ciscoImg from "../cisco.png"
import awsImg from "../aws.png"
import zoomImg from "../zoom.png"
import jamfImg from "../jamf.png";
import juniperImg from "../juniper.png";
import officeImg from "../office365.png"


const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
		padding: "20px"
	},
	title: {
		fontSize: 14,
	},
	mainCard: {
		borderRadius: "0",
		// padding:"20px"
	},
	pos: {
		marginBottom: 12,
	},
	searchbar: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		margin: '5px 5px 5px 0',
		height: '28px',
	},
	buttonSquare: {
		borderRadius: '5px'
	}
});

const layout = () => {
	const classes = useStyles();
	const mainTheme = useSelector(selectMainTheme);
	return (
		<>
			<div className={classes.root} >
				<Card className={classes.mainCard}>
					<Grid container>
						<Grid item lg={3} className='' style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)", height: "100vh" }}>
							<Box p={2}>
								<Typography variant='h6'>Refine By</Typography>
								<Box mt={2}>
									<ThemeProvider theme={mainTheme}>

										<Paper
											// component={motion.div}
											initial={{ y: -20, opacity: 0 }}
											animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
											className={`flex  shadow ${classes.buttonSquare}`}
											style={{ minWidth: "40px" }}
										>

											<TextField
												placeholder="Search"
												className="flex flex-1 mx-8 py-5"
												disableUnderline
												fullWidth
												InputProps={{
													disableUnderline: true,

												}}

											/>

											<IconButton
												aria-label="toggle password visibility"
												size="small"
												// disabled={props.currentSearch == ''}
												className=""
											>
												<Icon>search</Icon>
											</IconButton>
										</Paper>
									</ThemeProvider>
								</Box>
							</Box>
							<Divider />

							<Box p={2} className='flex justify-between items-center'>
								<Typography variant='subtitle2' style={{fontSize:"14px"}}>Integration Type</Typography>
								<Button className='flex items-center' variant='contained' style={{ borderRadius: "2px",width:"70px",background:"#f3f3f3",height:"27px" }}><Icon>
									<span class="material-symbols-outlined" style={{ fontSize: "14px",transform:"translateY(-4px)",display:"inline-block" }}>
										cancel
									</span></Icon>Clear</Button>
							</Box>

							<Box p={2}>
								<Box>
									<FormControlLabel
										control={<Checkbox color='default' name="checkedH" />}
										label="All"
									/>
								</Box>
								<Box>
									<FormControlLabel
										control={<Checkbox color='default' name="checkedH" />}
										label="Productivity"
									/>
								</Box>
								<Box>
									<FormControlLabel
										control={<Checkbox color='default' name="checkedH" />}
										label="Hosting"
									/>
								</Box>
								<Box>
									<FormControlLabel
										control={<Checkbox color='default' name="checkedH" />}
										label="Communication"
									/>
								</Box>
								<Box>
									<FormControlLabel
										control={<Checkbox color='default' name="checkedH" />}
										label="Asset and System Management"
									/>
								</Box>
								<Box>
									<FormControlLabel
										control={<Checkbox color='default' name="checkedH" />}
										label="Network Access Control"
									/>
								</Box>
							</Box>

						</Grid>
						<Grid item lg={9} style={{ padding: "10px" }}>
							<Grid container spacing={4}>
								<Grid item lg={4}>
									<Box className='flex items-center justify-center' style={{ border: "1px solid rgba(0,0,0,.05", height: "136px", borderRadius: "8px" }}>
										<img src={officeImg} alt="" />
									</Box>
									<Box px={2} mt={3}>
										<Typography variant='h6'>Office 365</Typography>
										<Typography variant='' style={{marginBottom:"15px",display:"inline-block"}}>Productivity</Typography>
										<Box className='flex items-center' style={{color:'#6D81C7'}}>
											<Icon><span style={{fontSize:"16px"}} class="material-symbols-outlined" >
											language
										</span></Icon>
												<Typography style={{transform:"translateY(3px)"}}>office365.com</Typography>
										</Box>
										
									</Box>
								</Grid>
								<Grid item lg={4}>
									<Box className='flex items-center justify-center' style={{ border: "1px solid rgba(0,0,0,.05", height: "136px", borderRadius: "8px" }}>
										<img src={awsImg} alt="" />
									</Box>
									<Box px={2} mt={3}>
										<Typography variant='h6'>AWS</Typography>
										<Typography variant='' style={{marginBottom:"15px",display:"inline-block"}}>Hosting</Typography>
										<Box className='flex items-center' style={{color:'#6D81C7'}}>
											<Icon><span style={{fontSize:"16px"}} class="material-symbols-outlined" >
											language
										</span></Icon>
												<Typography style={{transform:"translateY(3px)"}}>aws.com</Typography>
										</Box>
										
									</Box>
								</Grid>
								<Grid item lg={4}>
									<Box className='flex items-center justify-center' style={{ border: "1px solid rgba(0,0,0,.05", height: "136px", borderRadius: "8px" }}>
										<img src={zoomImg} alt="" />
									</Box>
									<Box px={2} mt={3}>
										<Typography variant='h6'>Zoom</Typography>
										<Typography variant='' style={{marginBottom:"15px",display:"inline-block"}}>Communication</Typography>
										<Box className='flex items-center' style={{color:'#6D81C7'}}>
											<Icon><span style={{fontSize:"16px"}} class="material-symbols-outlined" >
											language
										</span></Icon>
												<Typography style={{transform:"translateY(3px)"}}>zoom.us</Typography>
										</Box>
										
									</Box>
								</Grid>
								<Grid item lg={4}>
									<Box className='flex items-center justify-center' style={{ border: "1px solid rgba(0,0,0,.05", height: "136px", borderRadius: "8px" }}>
										<img src={jamfImg} alt="" />
									</Box>
									<Box px={2} mt={3}>
										<Typography variant='h6'>Jamf</Typography>
										<Typography variant='' style={{marginBottom:"15px",display:"inline-block",whiteSpace:"nowrap"}}>Asset & System Management</Typography>
										<Box className='flex items-center' style={{color:'#6D81C7'}}>
											<Icon><span style={{fontSize:"16px"}} class="material-symbols-outlined" >
											language
										</span></Icon>
												<Typography style={{transform:"translateY(3px)"}}>jamf.com</Typography>
										</Box>
										
									</Box>
								</Grid>
								<Grid item lg={4}>
									<Box className='flex items-center justify-center' style={{ border: "1px solid rgba(0,0,0,.05", height: "136px", borderRadius: "8px" }}>
										<img src={juniperImg} alt="" />
									</Box>
									<Box px={2} mt={3}>
										<Typography variant='h6'>Juniper</Typography>
										<Typography variant='' style={{marginBottom:"15px",display:"inline-block"}}>Network Access Control</Typography>
										<Box className='flex items-center' style={{color:'#6D81C7'}}>
											<Icon><span style={{fontSize:"16px"}} class="material-symbols-outlined" >
											language
										</span></Icon>
												<Typography style={{transform:"translateY(3px)"}}>juniper.com</Typography>
										</Box>
										
									</Box>
								</Grid>
								<Grid item lg={4}>
									<Box className='flex items-center justify-center' style={{ border: "1px solid rgba(0,0,0,.05", height: "136px", borderRadius: "8px" }}>
										<img src={ciscoImg} alt="" />
									</Box>
									<Box px={2} mt={3}>
										<Typography variant='h6'>Cisco</Typography>
										<Typography variant='' style={{marginBottom:"15px",display:"inline-block"}}>Network Access Control</Typography>
										<Box className='flex items-center' style={{color:'#6D81C7'}}>
											<Icon><span style={{fontSize:"16px"}} class="material-symbols-outlined" >
											language
										</span></Icon>
												<Typography style={{transform:"translateY(3px)"}}>cisco.com</Typography>
										</Box>
										
									</Box>
								
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Card>
			</div>
		</>
	)
}

export default layout
