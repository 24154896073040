import React, {useEffect,useState, useRef} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import FuseLoading from '@fuse/core/FuseLoading/FuseLoading';
import { withRouter } from 'react-router-dom';
import axios from "@fuse/utils/axios";
import { setServiceRequestId } from 'app/main/service-request/store/filterParamsReducer';
// import { setSerialNumberForServiceRequest } from 'app/main/service-request/store/filterParamsReducer';
import { setServiceTicketsCount } from 'app/main/assets/store/formViewDialogReducer'
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from '@fuse/hooks';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { Autocomplete } from '@material-ui/lab'
import { Controller, useForm } from 'react-hook-form'
import ExpandMore from '@material-ui/icons/ExpandMore'
import AlphabeticalSorting from '@fuse/utils/alphabeticalSort'
import useToast from '@fuse/hooks/useToast'
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'
import ServiceRequestStatusDialog from '@fuse/components/Modal/QuickAddServiceRequestStatus'
import ServiceRequestCategoryDialog from '@fuse/components/Modal/QuickAddServiceRequestCategory'
import ServiceRequestSourceDialog from '@fuse/components/Modal/QuickAddServiceRequestSource'
import LocationDialog from '@fuse/components/Modal/QuickAddLocationDialog'
import {
	Accordion, AccordionDetails, AccordionSummary, 
	DialogTitle, Drawer, IconButton, Paper, 
	TextField, Typography, Radio, Divider, CircularProgress} from '@material-ui/core'
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import AddServiceRequestFormDialog from 'app/main/service-request/components/addServiceRequestDialog';
import { openFormViewerDialog } from 'app/main/service-request/store/formViewDialogReducer';
// const useStyles = makeStyles({

//     root:{
//       '& .MuiDataGrid-columnsContainer':{
//         background: '#8f8f8f26'
//       }
//     }
// });

const useStyles = makeStyles(theme => ({
	loader: {
			display: 'flex',
			marginTop: '27px',
			marginLeft: '10px',
			'& > * + *': {
					marginLeft: theme.spacing(2),
			},
	},
	drawerWidth: {
			width: 600
	},
	uploadImageButton: {
			padding: '10px 36px',
			fontSize: '1.3rem',
			minWidth: '64px',
			boxSizing: 'border-box',
			transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
			fontFamily: 'Inter, sans-serif',
			fontWeight: 500,
			lineHeight: 1.75,
			borderRadius: '4px',
			textTransform: 'none',
			backgroundColor: '#6B2C57',
			color: '#ffffff',
			cursor: 'pointer',
			marginTop: "25px"
	},
	subSection: {
			marginLeft: '20px !important',
			marginRight: '20px !important',
			marginBottom: '20px !important',
			padding: '10px',
			backgroundColor: '#f4f4f4',
			borderRadius: '4px'
	},
	endButtons: {
			borderRadius: '4px'
	},
}))


function ServiceRequestsTab(props) {

	const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 430);

	const gridRef = useRef()
    
    const onGridReady = params => {
        // Following line to make the currently visible columns fit the screen  
        params.api.sizeColumnsToFit();
     
        // Following line dymanic set height to row on content
        params.api.resetRowHeights();
    };

    const columns = [
        { field: 'ticket_number', headerName: 'Ticket Number', minWidth: 170, width: 200, headerComponentFramework: CustomFilter,	
            renderCell: (params) =>{
                return (<a style={{color: "black",cursor: 'pointer'}} onClick={()=>redirectToServiceRequestsPage(params)}>{params.value}</a>)
            }
        },
        { field: 'serial_number', headerName: 'Serial Number', headerComponentFramework: CustomFilter, minWidth: 170, width: 200},
        { field: 'title', headerName: 'Title',headerComponentFramework: CustomFilter, minWidth: 150, width: 200},
        { field: 'priority', headerName: 'Priority',headerComponentFramework: CustomFilter, minWidth: 150, width: 200},
        { field: 'agent_name', headerName: 'Agent Name',headerComponentFramework: CustomFilter, minWidth: 150,	width: 200},
        // { field: 'service_request_status', headerName: 'Status',headerComponentFramework: CustomFilter,	width: 200,renderCell: (params)=>{
        //     return(
        //         <span style={{
        //             display: 'flex',
        //             gap: '6px',
        //             alignItems: 'center'
        //         }}>
        //             <span style={{
        //                 height:'10px',
        //                 width:'10px',
        //                 borderRadius: '50%',
        //                 display: 'inline-block',
        //                 marginLeft: '5px',
        //                 backgroundColor: params.value.status_color.code
        //             }}></span>
        //             <span style={{ display: "inline-block", fontSize: "15px" }}>{params.value.status_name}</span>
        //         </span>
        //     )
        // }}
    ];
    
    const classes = useStyles();
    const router = useRouter()
    const [serviceRequests,setServiceRequests] = useState([])
    const [loading,setLoading] = useState(false)
    const {assetDetails} = props

		
		//state for add service request
    const [expanded, setIsExpanded] = useState(false)
    const [serial_number, setSerial_number] = useState(null)
	const [suggestion, setSuggestion] = useState([])
	const [status, setStatus] = useState([])
	const [search, setSearch] = useState("")
    const [searchResult, setSearchResult] = useState(null)
	const [detailExpand, setDetailExpand] = useState(false)
	const [description, setDescription] = useState('')
	const [uploadingFile,setUploadingFile] = useState(false)
	const [fileLocation,setFileLocation] = useState('')
	const [locationExpand, setLocationExpand] = useState(false)
	const [fileName, setfileName] = useState('')
	const [usersMenu, setUsersMenu] = useState([])
	const [locationMenu, setLocationMenu] = useState([])
	const [source, setSource] = useState([])
	const [category, setCategory] = useState([])
	const [currentCategory, setCurrentCategory] = useState({ id: 0, category_name: '' });
	const [title, setTitle] = useState('')
    const [priority, setPriority] = useState(null)
    const [openServiceReqForm, setOpenServiceReqForm] = useState(false)
	const [currentStatus, setCurrentStatus ] = useState({ id: 0, status_name: '' });
	const [currentSource, setCurrentSource] = useState({ id: 0, source_name: '' });
	const [locationupdateSelection, setlocationupdateSelection] = useState('no')
	const [openStatusDialog, setOpenStatusDialog] = useState(false)
	const [openCategoryDialog, setOpenCategoryDialog] = useState(false)
	const [openSourceDialog, setOpenSourceDialog] = useState(false)
	const [createNewShippingLocation,setCreateNewShippingLocation] = useState(false)
	const uploadInput = useRef();
	const titleInputRef = useRef(""),
	agentInputRef = useRef(""),
	sourceInputRef = useRef(""),
	categoryInputRef = useRef(""),
	statusInputRef = useRef(""),
	priorityInputRef = useRef(""),
	locationInputRef = useRef(""),
	ticketTypeInputRef = useRef("")
	const dispatch = useDispatch()
	const toast = useToast(dispatch)
	const priorities = [
		{ 'id': 'high', 'name': 'High' },
		{ 'id': 'medium', 'name': 'Medium' },
		{ 'id': 'low', 'name': 'Low' }
	]
	const ticketTypes = [
		{name: 'Incident',value: 'incident'},
		{name: 'Request',value: 'request'}
	]
	const openState = useSelector((state) => state.ServiceRequestsApp.formViewDialog)
    function redirectToServiceRequestsPage(params){
        dispatch(setServiceRequestId(params.row.id))
        router.push('/service-tickets')
    }

    function redirectToServiceRequestsPageWithSerialNo(){
		dispatch(openFormViewerDialog());
    }

    function formatServiceRequests(serviceRequests){
        return serviceRequests.map((item)=>{
            return { 
                id: item.id,
                ticket_number: item.ticket_id,
                serial_number: item.asset.serial_number,
                title: item.title,
                priority: item.priority ? item.priority.charAt(0).toUpperCase() + item.priority.slice(1) : 'N/A',               
                agent_name: item.agent ? `${item.agent.first_name} ${item.agent.last_name}` : 'N/A',
                service_request_status: item.service_request_status ?? 'N/A',
            }
        })
    }
    function getServiceRequests(){
        if(!assetDetails) return
        // setLoading(true)
        axios.get(`/assets/fetch-asset-service-request/${assetDetails.id}`,{
        }).then((res) => {
            const { response } = res.data.data;
            setServiceRequests(formatServiceRequests(response))
						dispatch(setServiceTicketsCount(response.length))
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            // setLoading(false)
        })
    }

    useEffect(()=>{
        getServiceRequests()
    },[])

    if(loading){
        return(
            <FuseLoading/>
        )
    }

	// add service request
	useEffect(() => {
		fetchStatusSuggestions()
		fetchCategorySuggestions()
		fetchSourceSuggestions()
		fetchLocationSuggestions()
		fetchUserSuggestions()
		if(serial_number){
			setSearch(serial_number)
			getSearchResult(serial_number)
		}
	}, [serial_number])

	const getSearchResult = (value) => {
		if(!value) return
		axios.get(`/service/assets/get-search-result?serial_number=${value}`).then( res => {
			const { data } = res.data;
			setSearchResult(data.result)
			setValue('location',{
				...res.data.data.result.location
			})
		}).catch(err => {
			toast.error(err.response.data.message)
			console.log(err)
		})
	}

	const fetchUserSuggestions = () => {
		axios.get('/assets/fetch-users-suggestion').then(response => {
			let users = AlphabeticalSorting(response.data.data.users,'first_name')
			setUsersMenu(users)
		}).catch(err => {
			console.log('164=>',err);
			// toast.error('Something went wrong!')
		})
	}

	const fetchLocationSuggestions = (value = '') => {
		// setLoading(true)
		axios.get(`assets/fetch-location-suggestion?value=${value}`).then(response => {
			let locations = AlphabeticalSorting(response.data.data.locations,'location_name')
			// setLoading(false)
			setLocationMenu(locations)
		}).catch(err => {
			console.log('136=>',err);
			// toast.error('Something went wrong!')
		})
	}

	const fetchSourceSuggestions = (value = '') => {
		// setLoading(true)
		axios.get("/service-request-source").then(response => {
			// setLoading(false)
			setSource((AlphabeticalSorting(response.data.data,'source_name')).filter((item)=>{ 
				return Boolean( item.source_name)
			}))
		}).catch(err => {
			console.log('136=>',err);
			// toast.error('Something went wrong!')
		})
	}

	const fetchCategorySuggestions = (value = '') => {
		// setLoading(true)
		axios.get("/service-request-category").then(response => {
			// setLoading(false)
			setCategory((AlphabeticalSorting(response.data.data,'category_name')).filter((item)=>{ 
				return Boolean( item.category_name)
			}))
		}).catch(err => {
			console.log('136=>',err);
			// toast.error('Something went wrong!')
		})
	}

	function setDefault(){
		setCurrentStatus({ id: 0, status_name: '' })
		setCurrentCategory({ id: 0, category_name: '' })
		setCurrentSource({ id: 0, source_name: '' })
		// setValue('location', null)
		// setSearchResult(null)
		// setSearch('')
		setfileName('')
		setFileLocation('')
		setPriority(null)
		// setLocationMenu(locations)
		setlocationupdateSelection('no')
		setSuggestion([])
		setTitle('')
		setOpenServiceReqForm(false)
	}

	const onCancel = () => {
		setDefault()
	}

	function handleStatusDialogOpen(){
		setOpenStatusDialog(true)
	}
			

	const handleQueryInput =(value)=>{
		if(!value) return
		axios.get(`/service/assets/get-suggestions?query=${value}`).then( res => {
			if(res.data.message == "No suggestion found"){
				toast.error("No such asset found")
			}
			else {
				const { data } = res.data;
				setSuggestion(data);
			}
		}).catch(err => {
			console.log(err)
		})
	}

	const fetchStatusSuggestions = (value = '') => {
		// setLoading(true)
		axios.get("/service-request-status").then(response => {
			// setLoading(false)
			setStatus((AlphabeticalSorting(response.data.data,'status_name')).filter((item)=>{ 
				return Boolean( item.status_name)
			}))
		}).catch(err => {
			console.log('136=>',err);
			// toast.error('Something went wrong!')
		})
	}
	// const [openStatusDialog, setOpenStatusDialog] = useState(false)

	function submitDetailAccordian(){
		let expand = !detailExpand
		setDetailExpand(expand)
	}

	const defaultValues = {
		ticket_type: '',
		title: '',
		agent: null,
		description:'',
		location: null,
	}

	const { control, formState, handleSubmit, reset, setValue, getValues } = useForm({
		mode: 'onChange',
		defaultValues,
		// resolver: yupResolver(schema)
	});

	function submitLocationAccordian(){
		let expand = !locationExpand
		setLocationExpand(expand)
	}

	const onChangeImageUpload = (event) => {
		setUploadingFile(true)
		const selectedFile = event.target.files[0]
		if(!selectedFile) return
		if (selectedFile.size > 2000000) {
			toast.error("File size cannot exceed 2Mb!");
			setUploadingFile(true)
			return;
		} else {
			let formData = new FormData()
			formData.append("file", selectedFile)

			axios.post('/service/service-request-file-upload', formData ).then(response => {
			if(response.status === 201) {
				toast.error(response.data.message)
			}
			else{
				setfileName(selectedFile.name)
				setFileLocation(response.data.data.imageLocation)
			}
			}).catch(err => {
				console.log('136=>',err);
				toast.error('Something went wrong!')
			}).finally(() => {
				setUploadingFile(false)
			})
		}
	}


	const onSubmitAccordionExpand = () => {

		if( titleInputRef.current.value == '' 
		|| categoryInputRef.current.value == ''
		|| sourceInputRef.current.value == ''
		|| statusInputRef.current.value == ''
		|| priorityInputRef.current.value == null){
			setDetailExpand(true)
		}

		if( locationInputRef.current.value == ''){
			setLocationExpand(true)
		}
	}

	const manageCurrentStatus = (value) => {
		if(!value) return ''
		let currentLocation = status.filter(item => item.status_name === value)
		let finalVal = {id: currentLocation[0].id, status_name: currentLocation[0].status_name}
		setCurrentStatus(finalVal)
	}
	const manageCurrentCategory = (value) => {
		if(!value) return ''
		let currentLocation = category.filter(item => item.category_name === value)
		let finalVal = {id: currentLocation[0].id, category_name: currentLocation[0].category_name}
		setCurrentCategory(finalVal)
	}
	const manageCurrentSource = (value) => {
		if(!value) return ''
		let currentLocation = source.filter(item => item.source_name === value)
		let finalVal = {id: currentLocation[0].id, source_name: currentLocation[0].source_name}
		setCurrentSource(finalVal)
	}

	function handleSourceDialogOpen(){
		setOpenSourceDialog(true)
	}
	const wanttoUpdatelocation = (event) => {
		if(event.target.value == 'no'){
				setValue('location',{
					...searchResult?.location
				})
			
		}
				setlocationupdateSelection(event.target.value);
	};

	const handleRequestSubmit = (e)=>{
		e.preventDefault()

		if(!searchResult){
			toast.error('Enter valid serial number')
			return
		}

		let payload = {
			asset_id: searchResult?.asset_id,
			title: title,
			status_id: currentStatus.id,
			category_id: currentCategory.id,
			source_id: currentSource.id,
			priority: priority?.id,
			description: description,
			req_file: fileName,
			req_file_location: fileLocation,
			location_id: getValues('location')?.id,
			agent_id: getValues('agent').id,
			ticket_type: getValues('ticket_type').value
		}

		axios.post(`/service/request/submit`, payload, {
		}).then(results=>{
			toast.success(results.data.message)
			setDefault()
			getServiceRequests()
			
		}).catch(err=>{
			console.log(err)
			toast.error(err.response.data.message);
		}).finally(() => {
			// setTimeout(() => {
			// 	// setLoading(false)
			// }, 500)
		});
		
	}

	function statusFormReset(new_status=null){
		setOpenStatusDialog(false)

		//Set Quick added status value into status field
		if(new_status){setCurrentStatus({ id: new_status.id, status_name: new_status.status_name })}
	}
	function handleCategoryDialogOpen(){
		setOpenCategoryDialog(true)
	}
	function categoryFormReset(new_category=null){
		setOpenCategoryDialog(false)

		//Set Quick added category value into category field
		if(new_category){setCurrentCategory({ id: new_category.id, category_name: new_category.category_name })}
	}

	function sourceFormReset(new_source=null){
		setOpenSourceDialog(false)

		//Set Quick added source value into source field
		if(new_source){setCurrentSource({ id: new_source.id, source_name: new_source.source_name })}
	}
	const handleOpenLocationDialog = () => {
		setCreateNewShippingLocation(true)
	}

	const handleCloseLocationDialog = (new_shipping_location=null) => {
		setCreateNewShippingLocation(false)

		//Set Quick added LOCATION value into location field
		if(new_shipping_location){
			setValue('location',{
				id: new_shipping_location.id,
				location_name: new_shipping_location.location_name,
				address1: new_shipping_location.address1 || '',
				city: new_shipping_location.city || '',
				state: new_shipping_location.state || '',
				country: new_shipping_location.country || '',
			})
		}
	}

    return (
        <div className={classes.root}>

			<div style={{display: 'flex', justifyContent: 'flex-end'}}>
				{/* <Button
					variant="contained"
					color="secondary"
					className={`w-full ${classes.buttonSquare}`}
					style={{width:'210px', marginRight: '10px', marginBottom: '20px'}}
					onClick = {()=>{redirectToServiceRequestsPageWithSerialNo(props.assetDetails.serial_number)}}
					startIcon={
					<Icon fontSize="small" title="Add Asset">
						add
					</Icon>}>
						Add Service Request
				</Button> */}
				{/* <Button 
					style={{
						// marginLeft: '88%',
						marginBottom: '8px'
					}} 
					variant='contained' 
					color="primary"
					onClick = {()=>{redirectToServiceRequestsPageWithSerialNo(props.assetDetails.serial_number)}}
				>Add Service Tickets
				</Button> */}

				<Button className='button primary_maroon_outlined_btn mb-10'
					onClick = {()=>{redirectToServiceRequestsPageWithSerialNo(props.assetDetails.serial_number)}}
				>
          			<i className="icon ti ti-plus" />
					Add Service Tickets
				</Button>
			</div>

			{/* <DataGrid
				rows={serviceRequests}
				columns={columns}
				hideFooter={true}
			/> */}


			<div 
				className="ag-theme-alpine" 
				style={{
				width: "100%",
				height: windowHeight+105,
				fontSize:'12px'
				}}
			>
				<AgGridReact
					ref={gridRef}
					rowData={serviceRequests}
					animateRows
					onGridReady = { onGridReady }
					paginationPageSize={10}
					pagination
					defaultColDef={{
						resizable: true
					}}
				>
				{columns.map(header => {
					return(
						<AgGridColumn 
							key = {header.field} 
							field = {header.field}
							headerName = {header.headerName}  
							width = { header.width }
							minWidth = { header.minWidth }
							maxWidth = { header.maxWidth }
							sortable = {false}
							flex = {1}
							filter = "text"
							wrapText
							headerComponentFramework = {header.headerComponentFramework}								
						/>
					)
					
				})}
				</AgGridReact>
			</div>
			{openState.open && <AddServiceRequestFormDialog getServiceRequest = {getServiceRequests} asset={props?.assetDetails}/>}

        </div>
    )
}

export default withRouter(ServiceRequestsTab)
