import React, { useEffect, useRef } from 'react'
import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken = process.env.REACT_APP_MAP_KEY

class MapboxGLButtonControl {
  constructor({
    className = "",
    title = "",
    eventHandler = ()=>{}
  }) {
    this._className = className;
    this._title = title;
    this._eventHandler = eventHandler;
  }

  onAdd(map) {
    this._btn = document.createElement("button");
    this._btn.className = "mapboxgl-ctrl-icon" + " " + this._className;
    this._btn.type = "button";
    this._btn.title = this._title;
    this._btn.onclick = this._eventHandler;

    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
    this._container.appendChild(this._btn);
    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}

function Map({ mapData, mapCenter, mapZoom, handleMapMarkerClicked=() => {}, fullView=false }) {
  const mapContainerRef = useRef(null);
  const map = useRef(null);
  let CURRENT_OPEN_POPUP = null;

  //Format categories data
  function flattenCategories(categories){
    const flattenCategoriesObject = {}
    for(let [categoryKey, categoryValue] of Object.entries(categories)){
      for(let [key, value] of Object.entries(categoryValue)){
        //Skip those key whose value less than 0
        if(value > 0){
          flattenCategoriesObject[categoryKey] = {
            ...flattenCategoriesObject[categoryKey],
            [key]: value
          }
        }
      }
    }
    return flattenCategoriesObject
  }

  // Generate popup html
  function generatePopUpHTML(location_name, categories, longitude, latitude){
    return (`
      ${fullView ? `<h4 style="font-weight: 700;font-size: 15px;margin-bottom: 10px;">${location_name}</h4>` : ''}
      ${
        (Object.entries(categories)).reduce((res, [key, value]) => {
          res += `
          <div>
            <h5 role="button" onclick="handleOnClickCategory('${value.id},${longitude},${latitude}')">
              <span>${key}: ${value.count}</span>
              ${value?.disconnected 
                ? '<span class="dot_status_holder disconnect"></span>'
                : value?.unknown ? '<span class="dot_status_holder unknown"></span>' : '<span class="dot_status_holder"></span>'}
            </h5>
            <input type="checkbox" name="checkbox" id="${key}" value="value">
            <span class="icon"></span>
            <label for="${key}">
              <ul>
              ${(Object.keys(value).filter(i => (i !== 'id' && i !== 'count' ))).reduce((res, curr) => {
                res += `
                  <li class="${curr}" role="button" onclick="handleOnClickConnectionStatus('${value.id},${longitude},${latitude},${curr}')">
                    ${curr}: ${value[curr]}
                    <span class="info_icon_holder">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_4020_93070)">
                        <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#F04438" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8 5.33325H8.00667" stroke="#F04438" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M7.3335 8H8.00016V10.6667H8.66683" stroke="#F04438" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_4020_93070">
                        <rect width="16" height="16" fill="white"/>
                        </clipPath>
                        </defs>
                      </svg>
                      <span class="tooltip_holder">Disconnected at 3pm due to inadequate power supply</span>
                    </span>
                  </li>`
                return res
              }, '')
                }
              </ul>
            </label>
          </div>
          `
          return res
        }, '')
      }
    `)
  }

  // Initialize map when component mounts
  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      // style: 'mapbox://styles/mapbox/light-v9',
      style: 'mapbox://styles/dalos/clrspbp3y00n501pf0p0h2gi9',
      center: mapCenter,
      zoom: mapZoom,
      maxZoom: 17, // City level
      minZoom: 1, // 7: State level
      pitch: 0,
      // bearing: 60,
      // antialias: true
    });

    //Initially set zoom on scroll disable in map
    map.current.scrollZoom.disable();

    // Reset pitch to 0 for zoom level 1
    map.current.on('zoomend',()=>{
      const currentZoom = Math.floor(map.current.getZoom())
      if( currentZoom == 1 ) map.current.setPitch(0)
    })

    // Adding control button into map
    const resetDefaultMapView = new MapboxGLButtonControl({
      className: "mapbox-gl-reset_view",
      title: "Reset view",
      eventHandler: (event)=>{
        if(CURRENT_OPEN_POPUP) CURRENT_OPEN_POPUP.remove()
        map.current.flyTo({
          center: mapCenter,
          zoom: mapZoom,
          pitch: 0
        });
      }
    });
    map.current.addControl(new mapboxgl.NavigationControl());
    map.current.addControl(resetDefaultMapView, "top-right");

    const markerColorDefault = ['==', ['get', 'marker_color'], '#3FB1CE']; // default
    const markerColorRed = ['==', ['get', 'marker_color'], '#ff0000']; // red

    const colors = ['#3FB1CE','#ff0000'];

    map.current.on('load', () => {
      // console.log('mapData: ',mapData);
      const points = mapData.map(item => ({
        type: 'Feature',
        properties: {
          marker_color: item.marker_color,
          total_asset_count: item.total_asset_count,
          category:{...item.category},
          location_name: item.location_name,
          longitude: parseFloat(item.longitude),
          latitude: parseFloat(item.latitude)
        },
        geometry: {
          type: 'Point',
          coordinates: [parseFloat(item.longitude), parseFloat(item.latitude)],
        },
      }));

      // add a clustered GeoJSON source for locations
      map.current.addSource('locations', {
        'type': 'geojson',
        data: {
          "type": "FeatureCollection",
          "features": points
        },
        'cluster': true,
        'clusterRadius': 80,
        'clusterProperties': {
          // keep separate counts for each magnitude category in a cluster
          'markerColorDefault': ['+', ['case', markerColorDefault, 1, 0]],
          'markerColorRed': ['+', ['case', markerColorRed, 1, 0]]
        }
      });

      map.current.loadImage('https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png', (error, image) => {
        if (error) throw error;
        map.current.addImage('marker-icon', image, { 'sdf': true });

        map.current.addLayer({
          'id': 'marker',
          'source': 'locations',
          // 'source-layer': 'food_stores-8sw1vy',
          'type': 'symbol',
          'layout': {
            'icon-image': 'marker-icon',
            'icon-size': 0.6
          },
          'paint': {
            'icon-color': [
              'match',
              ['get', 'marker_color'], // Use the result 'marker_color' property
              '#3FB1CE',
              '#3FB1CE',
              '#ff0000',
              '#ff0000',
              '#3FB1CE' // any other store type
            ]
          }
        });

        map.current.addLayer({
          id: 'clusters',
          type: 'circle',
          source: 'locations',
          filter: ['has', 'point_count'],
          paint: {
            // with three steps to implement three types of circles:
            //   * Blue, 20px circles when point count is less than 100
            //   * Red, 40px circles when point count is greater than or equal to 750
            'circle-color': [
              'match',
              ['get', 'markerColorRed'], // Get the value of markerColorRed property
              0, colors[0], // If markerColorRed is 0, set the circle color to blue
              colors[1] // If markerColorRed is not 0, set the circle color to red
          ],
            'circle-radius': [
              'step',
              ['get', 'point_count'],
              20,
              100,
              30,
              750,
              40
            ]
          }
        });

        map.current.addLayer({
          id: 'cluster-count',
          type: 'symbol',
          source: 'locations',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': ['get', 'point_count_abbreviated'],
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 12
          }
        });
      })


      // circle and symbol layers for rendering individual locations (unclustered points)
      // map.current.addLayer({
      //   'id': 'marker',
      //   'type': 'circle',
      //   // 'type': 'symbol',
      //   'source': 'locations',
      //   'filter': ['!=', 'cluster', true],
      //   // 'icon-image': 'marker-15',
      //   'paint': {
      //     'circle-color': [
      //       'case',
      //       markerColorDefault,
      //       colors[0],
      //       markerColorRed,
      //       colors[1],
      //       'green'
      //     ],
      //     'circle-opacity': 1,
      //     'circle-radius': 12
      //   }
      // });

      // Change the cursor to a pointer when hovering over the earthquake_label layer
      map.current.on('mouseenter', 'marker', function() {
        map.current.getCanvas().style.cursor = 'pointer';
      });

      // Change it back to the default cursor when it leaves
      map.current.on('mouseleave', 'marker', function() {
        map.current.getCanvas().style.cursor = '';
      });

      // Change the cursor to a pointer when hovering over the clusters layer
      map.current.on('mouseenter', 'clusters', function() {
        map.current.getCanvas().style.cursor = 'pointer';
      });

      // Change it back to the default cursor when it leaves
      map.current.on('mouseleave', 'clusters', function() {
        map.current.getCanvas().style.cursor = '';
      });

      // inspect a cluster on click
      map.current.on('click', 'clusters', (e) => {
        const features = map.current.queryRenderedFeatures(e.point, {
          layers: ['clusters']
        });
        const clusterId = features[0].properties.cluster_id;
        map.current.getSource('locations').getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
            if (err) return;
            map.current.easeTo({
              center: features[0].geometry.coordinates,
              zoom
            });
          }
        );
      });

      // Add a click event listener to the map
      map.current.on('click', 'marker', function(e) {
        const feature = e.features[0]; // Get the clicked feature
        const categories = flattenCategories(JSON.parse(feature.properties.category))
        const popupHTML = generatePopUpHTML(feature.properties.location_name, categories, feature.properties.longitude, feature.properties.latitude)
        const popUp = new mapboxgl.Popup()
          .setLngLat(feature.geometry.coordinates) // Set the popup location to the clicked feature coordinates
          .setHTML(popupHTML) // Set the popup content
          .addTo(map.current); // Add the popup to the map
        CURRENT_OPEN_POPUP = popUp
        popUp.on('close', function() {
          handleMapMarkerClicked(false);
        })
        map.current.flyTo({
          center: feature.geometry.coordinates,
          zoom: 17,
          pitch: 65
        });
        handleMapMarkerClicked(true)
      });
    });

    //Attach category and connection status redirection event
    window.handleOnClickCategory = function( params ) {
      const categoryId = params.trim().split(',')[0]
      const longitude = params.trim().split(',')[1]
      const latitude = params.trim().split(',')[2]
      let link = `${window.location.origin}/assets-list?category_id=${categoryId}&longitude=${longitude}&latitude=${latitude}`
      window.open(link, '_blank')
    };

    window.handleOnClickConnectionStatus = function( params ){
      const categoryId = params.trim().split(',')[0]
      const longitude = params.trim().split(',')[1]
      const latitude = params.trim().split(',')[2]
      const networkStatus = params.trim().split(',')[3]
      let link = `${window.location.origin}/assets-list?category_id=${categoryId}&longitude=${longitude}&latitude=${latitude}&network_status=${networkStatus}`
      window.open(link, '_blank')
    }
    // Clean up on unmount
    return () => {
      map.current.remove();
    }
  }, [mapData]);

  function handleOnClick(){
    map.current.scrollZoom.enable()
  }

  function handleOnMouseLeave(){
    map.current.scrollZoom.disable()
  }

  return (
    <div
      style={{ width: '100%', height: '100%' }}
      className='canvas_holder'
      ref={mapContainerRef}
      onMouseLeave={handleOnMouseLeave}
      onClick={handleOnClick}
    />
  )
}

export default Map
