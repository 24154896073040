import _ from '@lodash';
import 'ag-grid-enterprise';
import Icon from '@material-ui/core/Icon';
import { useEffect, useState, useRef } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import useRouter from '@fuse/hooks/useRouter';
import axios from "@fuse/utils/axios";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import { Button } from '@material-ui/core';
import useToast from '@fuse/hooks/useToast'
import { useDispatch, useSelector } from 'react-redux'
import { setfilterlist, setrowGroupModel } from '../store/filterParamsReducer';
import 'ag-grid-enterprise';
import 'styles/ag-grid-header-style.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import GeneralModal from '@fuse/components/Modal/GeneralModal';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
	  cardGen: {
        width: '20%',
        height:"100%"
    },
	searchbar: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		margin:'5px 5px 5px 0',
		height:'28px'
	},
	 activeTab: {
        background:"rgba(107, 44, 87, 0.04);",
        borderRadius:"0",
	 },
	buttonSquare : {
		borderRadius:'5px'
	},
	btn:{
		background:"#30C90A",
		width:"120px",
		borderRadius:"17px",
		color:"#fff"
	},
	icon:{
	fontSize:"18px",
		color:"#61656F",
		display:"inline-block"
	}
});

function ListView(props) {
	const table_name = "ARCHIVED_ASSETS";
    const classes = useStyles();
	const transform_words = {
		id: 'ID',
		sku: 'SKU',
		eol: 'EOL',
		eosl: 'EOSL',
		no: 'No.'
	};

	const [open, setOpen] = useState(false);
	const [fullWidth, setFullWidth] = useState(true);
	const [maxWidth, setMaxWidth] = useState('lg');	
	const [tabState, setTabState] = useState("information");
	const router = useRouter()
	const dispatch = useDispatch();
	const toast = useToast(dispatch)
	const [gridApi, setGridApi] = useState(null);
	const gridRef = useRef();
	const skipHeader = [
		'id','model_id','shipping_location_details','status_value','contract_header',
		'assigned_user_details','source','portal','asset_type',
		'purchase_price','purchase_date','install_date','order_number','contract_no',
		'sku','make','city','eol','eosl','service_id','warranty_end_date',
		'name','sub_category','original_info','image_name', 'contract_status_value'
	]
	const [ displayButton, setDisplayButton ] = useState(false);
	const [ statusMenu,setStatusMenu ] = useState([])
	const [ assetId, setAssetId ] = useState(null)
	const [ assetDetail, setAssetDetails ] = useState(null)

	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
	const [tabValue, setTabValue] = useState(0)
	const [groupByArr, setGroupByArr] = useState([])
	const [logs,setLogs] = useState([]);

	const [ windowHeight, setWindowHeight ] = useState(window.innerHeight-330);
	const query_data = useSelector(
    (state) => state.reportApp.reportViewDialogReducer.quick_filter
  );
	function TabButton(props) {
		const { activeClass, tab, tabState, tabStateChange, ...restProps } = props;
		const isActive = tab == tabState;
		return (
			<Button 
				{...restProps} 
				onClick={() => tabStateChange(tab)} 
				className={restProps.className && isActive ? activeClass : ""}
			>
				{props.children}
			</Button>
		)
	}

	function Close(){
		setOpen(false)
		setTabState('information')
	}
    
	function TabContainer(props) {
		const { tabState, tab } = props;
		const shouldRender = tab == tabState;
		return shouldRender ? props.children : <></>
	}

	const generateHeaderLabel = (header) => {
		if(header == 'serial_no'){
			return 'Serial Number'
		}
		if(header == 'model'){
			return 'Model Name'
		}
		if(header === "location_name") {
			return "Location";
		}

		if(header === 'detailed_description_of_the_issue') {
			return 'Issue Description'
		}
	  
		if(header === "user_name") {
			return "User";
		}
		return header.split('_').map(word => {
			if(word in transform_words) return transform_words[word];
			return word.charAt(0).toUpperCase() + word.substring(1)
		}).join(' ')
	}

	const handleClickOpen = (asset_id) => {
		router.push(`/archived-assets/${asset_id}`)
	};

	const handleRestore = () => {
		if(assetDetail !== null) {
			axios.delete(`assets/restore-asset/${assetDetail.id}`).then((res) => {
				props.fetchAssets()
				toast.success(res.data.message)
			}).catch(err => {
				console.log('err',err);
				toast.error(err.response.data.message)
			})
		}
		
	}


	const handleConfirmRestore = (assetData) => {
		setAssetDetails(assetData)
	}


	const handleCloseRestore = () => {
		setAssetDetails(null)
	}

	function formatString(str){
		if(!str) return ''
		return str.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
	}

	function cellStyle(params){
		return{color: params.data?.status_value?.status_color?.code}
	}
	
	const onGridReady = (params) => {
		setGridApi(params);
		props.endUsersAccounts.forEach((list) => {
			for(let [fieldName, value] of Object.entries(list)){
				if(value === '-') list[fieldName] = `No ${formatString(fieldName)}`
			}
		})
		if(router.query.report_name == undefined) {
			// loadInitialState (params)
			dispatch(setfilterlist({}));
			dispatch(setrowGroupModel([]));
		}
	}

	const onColumnMoved = (params) =>{
		if(router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView(rows);
		}
	}

	const onColumnPinned = (params) =>{
		if(router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView(rows);
		}
	}

	function onColumnVisible(params){
		const rows = params.columnApi.getColumnState();
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row=>{
			const { hide } = row;
			if(hide) {
				++total_hidden_columns;
			}
		});
		if( total_hidden_columns < total_no_of_columns){
			if(router.query.report_name == undefined) {
				saveView(rows);
			}
		}
	}
	
	const onRangeSelectionChanged = (params) => {

		let columnCount = 0 ;
		let startRowIndex = 0 ;
		let endRowIndex =  0 ;
		
		const rows = params.api.getCellRanges();

		if(rows.length != 0){
			columnCount = params.api.getCellRanges()[0].columns.length ;
			startRowIndex = params.api.getCellRanges()[0].startRow.rowIndex ;
			endRowIndex = params.api.getCellRanges()[0].endRow.rowIndex ;			
		}

		if(columnCount > 1 || startRowIndex != endRowIndex  ){
			setDisplayButton(true);
		}else{
			setDisplayButton(false);
		}
	}

	function onFilterChanged(event){		
		const rows = event.api.getFilterModel();
		// to show No result found in column filter
		dispatch(setfilterlist(rows));
		saveView("N/A", rows)

		// validation for no-overlay 
		const total_rows = event.api.getDisplayedRowCount();
		if(total_rows === 0 ){
			event.api.showNoRowsOverlay();
		}else{
			event.api.hideOverlay();
		}
	}
	function onSortChanged(params){
    if (router.query.report_name == undefined) {
      const rows = params.columnApi.getColumnState();
      saveView(rows);
    }
  }

	function onColumnRowGroupChanged(event){
		if(router.query.report_name == undefined){
			const rows = event.columnApi.getColumnState();
			const first_row = rows[0];
			if(first_row.colId !== 'ag-Grid-AutoColumn'){
				dispatch(setrowGroupModel([]));
				saveView(rows);
			}else{
				dispatch(setrowGroupModel(rows));
			}
		}
		if(event.columns.length == 0) {
			setGroupByArr([])
		}else{
			setGroupByArr(event.columns)
		}
	}

	function saveView (rows, filter){
		let payload = {}
		if(rows == 'N/A'){
			payload = {
				filter:  JSON.stringify(filter),
				table_name : table_name
			}
		}else{
			payload = {
				order:  JSON.stringify(rows),
				table_name : table_name
			}
		} 
		// const order = JSON.stringify(rows);
		axios.post('/user-view/save-view-order',payload)
		.then(()=>{
			console.log('view updated')
		})
		.catch(err=>{
			console.log(err);
		})
	}

	function loadInitialState (params){
		axios.get(`/user-view/show-view-order?table_name=${table_name}`)
		.then(results=>{
			const { data } = results.data;
			if(data !== null){
				const order = JSON.parse(data.order);
				const filter = JSON.parse(data.filter);
				params.columnApi.setColumnState(order);
				params.api.setFilterModel(filter);
			}
		})
		.catch(err=>{
			console.log(err);
		})
  }
	function printSortStateToConsole(params){
    if (router.query.report_name == undefined) {
      const rows = params.columnApi.getColumnState();
      saveView(rows);
    }
  }

	// useEffect(()=>{
	// 	gridRef.current.api.setQuickFilter(
	// 		query_data
	// 	  );
	// },[query_data])

	// useEffect(() => {
	// 	return () => {
	// 			dispatch(setQuickFilter(null))
	// 	}
	// }, [])

	const onFirstDataRendered = (params) => {
		params.columnApi.sizeColumnsToFit(4500);
	};

	const frameworkComponents = {
		customFilter: CustomFilter,
	};

	useEffect(()=>{
		if(!router.params?.serial_no) return
		handleClickOpen(router.params.serial_no)
	},[])

	return (
		<>	
		<AgGridFilterGroupView
				tableRef={gridRef} 
				setRowGroupPanelShow={setRowGroupPanelShow} 
				groupByArrFromList ={ groupByArr }
				tableName= {'Archived Asset'}
				displayTableName={'Archived Assets'}
			/>		
			<div 
				className="ag-theme-alpine" 
				style={{
					width: "100%",
					height:windowHeight+165,
					fontSize:'12px'
				}}
			>
				<AgGridReact
					ref={gridRef}
					headerClass =  {"ag-right-aligned-header"}
					onFirstDataRendered={onFirstDataRendered}
					suppressRowClickSelection={true}
					onGridReady = { onGridReady }
					animateRows = { true }
					rowData={props.endUsersAccounts}
					rowHeight = {30}
					// Set the desired sheet name
					defaultExcelExportParams={{
						sheetName: 'Dalos-Source'
					}}
					// no rows overlay 
					overlayNoRowsTemplate={'<span>No Result Found</span>'}

					// Row Grouping conditions
					showOpenedGroup={true}
					suppressDragLeaveHidesColumns={true}
					suppressMakeColumnVisibleAfterUnGroup={true}
					// autoGroupColumnDef={{ minWidth: 500 }}
					rowGroupPanelShow={ rowGroupPanelShow }

					onColumnMoved = { onColumnMoved }
					onColumnPinned = { onColumnPinned }
					onColumnVisible = { onColumnVisible }
					onSortChanged = { onSortChanged }
					groupHeaderHeight ={1}
					floatingFiltersHeight = {40}

					// status bar enabled on range selection
					enableRangeSelection={ true }
					statusBar={{
						statusPanels: [
						{ 
							statusPanel: 'agAggregationComponent' },
						],
					}}

					// to check the the no. of rows selected during range selection
					onRangeSelectionChanged = { onRangeSelectionChanged }						

					onFilterChanged = { onFilterChanged }	
					
					// on column group changed
					onColumnRowGroupChanged = { onColumnRowGroupChanged }

					cacheQuickFilter={true}

					pagination={true}

					frameworkComponents={frameworkComponents}

					defaultColDef={{
						// flex: 1,
						// minWidth: 180,
						sortable: true,
						resizable: true
					}}
				>
					<AgGridColumn
						lockPosition={true}
						rowSelection={'single'}
						width={100}
            minWidth={100}
            maxWidth={100}
						suppressSizeToFit={true}
						headerName="Actions"
						pinned= 'right' 
						className="action_field"
						lockPinned={true}
						cellRendererFramework={({data}) =>{
							if(data){
								return (
									<>
											{(props.viewAssetPermission.length > 0 || props.viewAssetPermission == 'all' ||
											(props.viewAssetPermission.map(i=>i == data.location_obj.id).length >0)
										) && 
											<Tooltip id="view" title="View" placement="top">
												{/* <Icon onClick={()=>handleClickOpen(data.id)} fontSize='small' style={{color: '#1d2939',paddingBottom: '3px',paddingRight:'30px',fontSize:'15px',cursor:'pointer'}} >visibility</Icon> */}
												<i style={{color: '#1D2939', fontSize: '20px', padding: '0 6px', cursor: 'pointer'}} className="ti ti-eye" onClick={()=>handleClickOpen(data.id)} />
											</Tooltip>
										}

										{(props.createAssetPermission.length > 0 || props.createAssetPermission == 'all' ||
											(props.createAssetPermission.map(i=>i == data.location_obj.id).length >0)
										) && <Tooltip id="restore" title="Restore" placement="top">
											{/* <Icon onClick={() => handleRestore(data.id)} style={{color: '#1d2939',paddingBottom: '3px',paddingRight:'30px',fontSize:'15px',cursor:'pointer'}}>unarchive_outlined</Icon> */}
											<i style={{color: '#1D2939', fontSize: '20px', padding: '0 6px', cursor: 'pointer'}} className="ti ti-reload" onClick={()=>handleConfirmRestore(data)} />
										</Tooltip>}
									</>
								)
							}else{
								return (<></>)
							}
						}}
					>
					</AgGridColumn>
					{Object.keys(props.endUsersAccounts[0]).map(header => {
						if(!skipHeader.includes(header)){
							if(header === 'serial_no'){
								return (
									<AgGridColumn 
										field={header}
										suppressSizeToFit={true}
										key = { header }
										flex={1}
										minWidth={200}
										suppressRowClickSelection={true}								
										headerName={generateHeaderLabel(header)}  
										sortable={ true } filter="text"
										headerComponentFramework={CustomFilter} 
									>
									</AgGridColumn>
								)
							}if(header == 'contract_status'){
								return (
									<AgGridColumn 
										field={header}
										key = { header } 
										suppressSizeToFit={true}
										headerName={generateHeaderLabel(header)}  
										sortable={ true }
										flex={1}
										minWidth={200}
										cellRendererFramework={(event) =>{ 
											if(event.colDef.field === "contract_status" ){
												return (
													<span>
														<span style={{
															height:'10px',
															width:'10px',
															marginTop: '2px',
															borderRadius: '50%',
															display: 'inline-block',
															backgroundColor: event.data?.contract_status_value?.status_color?.code
														}}></span>
														<span style={{marginLeft: '5px'}}>{event.data?.contract_status_value?.status_name}</span>
													</span>
												)
											}else if(event.colDef.headerName == 'Group' && event.node.field == 'status'){
												return <><span>{event.value}</span></>
											}else{
												return(<><span>{event.value}</span></>)
											}											
										}}
										headerComponentFramework={CustomFilter} filter="text" 
									>
									</AgGridColumn>
								)
							}
							else if(header === 'asset_tag'){
								return (
									<AgGridColumn
										field={header}
										key = { header } 
										suppressSizeToFit={true}
										flex={1}
										minWidth={200}
										headerName={generateHeaderLabel(header)}  
										sortable={ true }
										headerComponentFramework={CustomFilter} filter="text" 
									>
									</AgGridColumn>
								)
							}
							if(header === 'serial_no'){
								return (
									<AgGridColumn 
										field={header}
										key = { header } 
										suppressSizeToFit={true}
										flex={1}
										minWidth={200}
										headerName={generateHeaderLabel(header)}  
										sortable={ true } filter="text"
										headerComponentFramework={CustomFilter} 
									>
									</AgGridColumn>
								)
							}
							else if(header != 'location_obj'){
								if(header == 'status'){
									return (
										<AgGridColumn 
											field={header}
											key = { header } 
											flex={1}
											minWidth={200}
											suppressSizeToFit={true}
											headerName={generateHeaderLabel(header)}  
											sortable={ true }
											cellRendererFramework={(event) =>{ 
												if(event.colDef.field === "status" ){
													return (
														<span>
															<span style={{
																height:'10px',
																width:'10px',
																marginTop: '2px',
																borderRadius: '50%',
																display: 'inline-block',
																backgroundColor: event.data?.status_value?.status_color?.code
															}}></span>
															<span style={{marginLeft: '5px'}}>{event.data?.status_value?.status_name}</span>
														</span>
													)
												}else if(event.colDef.headerName == 'Group' && event.node.field == 'status'){
													return <><span>{event.value}</span></>
												}else{
													return(<><span>{event.value}</span></>)
												}											
											}}
											headerComponentFramework={CustomFilter} filter="text" 
										>
										</AgGridColumn>
									)
								}
								else if(header == 'location'){
									return (
										<AgGridColumn 
											field={header}
											key = { header }
											minWidth={200}
											flex={1}
											// keyCreator={ (params) => {
											// 	return params.value.name;
											// }}
											
											headerName={generateHeaderLabel(header)}  
											sortable={ true }
											suppressSizeToFit={true}
											headerComponentFramework={CustomFilter} filter="text"
										>
										</AgGridColumn>
									)
								}
								else if(header == 'user_name'){
									return (
										<AgGridColumn 
											field={header}
											key = { header }
											flex={1} 
											minWidth={200}
											suppressSizeToFit={true}
											headerName={generateHeaderLabel(header)}  
											sortable={ true }
											headerComponentFramework={CustomFilter} filter="text" 
										>
										</AgGridColumn>
									)
								}								
								else if(header == 'name'){
									return (
										<AgGridColumn 
											field={header}
											key = { header } 
											suppressSizeToFit={true}
											headerName={generateHeaderLabel(header)}
											sortable={ true }
											flex={1}
											minWidth={200}
											headerComponentFramework={CustomFilter} filter="text" 
										>
										</AgGridColumn>
									)
								}
								else if(header == 'notes'){
									return (
										<AgGridColumn 
											field={header}
											key = { header }
											suppressSizeToFit={true}
											headerName={generateHeaderLabel(header)}  
											sortable={ true }
											flex={1}
											minWidth={200}
											headerComponentFramework={CustomFilter} filter="text" 
										>
										</AgGridColumn>
									)
								}
								else{
									return (
										<AgGridColumn 
											field={header}
											key = { header } 
											suppressSizeToFit={true}
											headerName={generateHeaderLabel(header)}  
											sortable={ true }
											flex={1}
											minWidth={200}
											headerComponentFramework={CustomFilter} filter="text" 
										>
										</AgGridColumn>
									)
								}
							}
							else if(header == 'purchase_price'){
								return (
									<AgGridColumn 
										field={ header }
										key = { header } 
										headerName={generateHeaderLabel(header)}  
										sortable={ true }
										flex={1}
										minWidth={200}
										suppressSizeToFit={true}
										headerComponentFramework={CustomFilter} filter="text" 
									>
									</AgGridColumn>
								)
							}
						}							
					})}
				</AgGridReact>
			</div>
			<GeneralModal
				open={assetDetail}
				title={'Are you sure to restore asset?'}
				text={<h4>Are you sure to restore this Contract named as <b>{assetDetail?.asset_name}</b>?</h4>}
				handleClose={handleCloseRestore}
				detailMessage={'Your asset will be moved to assets if you proceed'}
				handleConfirm={handleRestore}
				actionButtonName={'Yes, Procced!'}
			/>
		</>
	);
}

export default ListView