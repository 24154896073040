import React, { useCallback, useEffect, useRef, useState } from "react";
import { Popover, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useCurrentUser, usePusher } from "@fuse/hooks";
import axios from "@fuse/utils/axios";
import useRouter from '@fuse/hooks/useRouter';
import AddDashboardAlert from "app/main/assets-dashboard/components/addAlert";
import { formatDateTo_MM_DD_YYYY_HH_MM_SS } from "@fuse/utils/dateOperations";
import { setAssetNotification, setContractNotification } from 'app/store/fuse/notificationSlice';
import { useDispatch, useSelector } from "react-redux";

export default function Notification() {
    const { channel, channelName } = usePusher();
    const dispatch = useDispatch()
    const currentLoggedInUser = (useCurrentUser()).data;
    const router = useRouter();
    const limit = 100, page = useRef(0);
    const {
		assetNotificationCount,  contractNotificationCount
    } = useSelector(({ fuse }) => fuse.notifications);
    const notificationLabels = [
        {
            title: 'Assets',
            count: 0,
            type: 'asset'
        },
        {
            title: 'Contracts',
            count: 0,
            type: 'contract'
        },
        // {
        //     title: 'Subscriptions',
        //     count: 0,
        //     type: 'subscription'
        // },
        {
            title: 'IoT',
            count: 0,
            type: 'iot'
        }
    ]

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [lists, setLists] = useState([]);
    const [ openAlertList, setOpenAlertList ] = useState(false);
    const [ moduleName, setModuleName ] = useState('');
    const [ notifications, setNotifications ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ hasUnreadNotifications, setHasUnreadNotifications ] = useState(false);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    function getCurrentLoggedinUser() {
        return currentLoggedInUser;
    }

    function handleOnClickMenuItem( moduleName ){
        setOpenAlertList(true)
        setModuleName(moduleName)
    }

    const refreshNotifications = () => {
        setHasUnreadNotifications(false)
        axios.get("/notifications/count")
            .then((res) => {
                let assetCount = 0;
                let contractCount = 0;
                const { totalNotificationsCount } = res.data.data;
                const unreadNotificationsCount = totalNotificationsCount.reduce((res, curr) =>{
                    if(curr.type === "asset") {
                        assetCount = curr.count;
                    }
                    if(curr.type === "contract") {
                        contractCount = curr.count;
                    }
                    res[curr.type] = curr.count
                    return res;
                },{});

                dispatch(setAssetNotification({
                    assetNotificationCount: assetCount
                }))
                dispatch(setContractNotification({
                    contractNotificationCount: contractCount
                }))
                for(let item of notificationLabels){
                    if(unreadNotificationsCount[item.type]){
                        setHasUnreadNotifications(true)
                        item.count = unreadNotificationsCount[item.type]
                    }
                }
                setLists(notificationLabels)
            }).catch((err) => console.log(err));
    };

    function flattenNotifications( notifications ){
        if(moduleName !== 'asset') return notifications;
        return notifications.map((notification) => {
            return {
                ...notification,
                title: (()=>{
                    const splitTitle = notification.title.split(" ")
                    const dateTime = new Date(`${splitTitle[splitTitle.length - 2]} ${splitTitle[splitTitle.length - 1].slice(0, -1)}`);
                    const localDateTime = formatDateTo_MM_DD_YYYY_HH_MM_SS(dateTime.toUTCString())
                    return `${splitTitle.slice(0,-2).join(' ')} ${localDateTime}.`
                })()
            }
        })
    }

    function getNotifications( moduleName, refreshBtnClicked=false ){
        if(!moduleName) return
        axios.get(`/notifications?type=${moduleName}&limit=${limit}&page=${page.current}`).then((res)=>{
            const { notifications: notification } = res.data.data;
            if(!refreshBtnClicked) setNotifications([...notifications ,...flattenNotifications(notification)])
            else setNotifications([...flattenNotifications(notification)])
            page.current++;
        }).catch((err)=>{
            console.log(err)
        }).finally(()=>setLoading(false))
    }

    function onClickRefreshBtn(){
        page.current = 0;
        getNotifications(moduleName, true)
    }

    function markAllAsRead(){
        axios.put(`/notifications/update-read-status-of-all-notifications?type=${moduleName}`).then((res)=>{
            if(moduleName === 'asset'){
                dispatch(setAssetNotification({
                    assetNotificationCount: 0
                }))
            }
            if(moduleName === 'contract'){
                dispatch(setContractNotification({
                    contractNotificationCount: 0
                }))
            }
            setDefaults()
            refreshNotifications()
        }).catch((err)=>{
            console.log(err)
        }).finally(()=>setLoading(false))
    }

    function updateReadStatusOnClickNotification(notificationId){
        if(!Boolean(notificationId)) return
        axios.put(`/notifications/update-read-status/${notificationId}`).then((res)=>{
            refreshNotifications()
            // getNotifications(moduleName)
        }).catch((err)=>{
            console.log(err)
        })
    }

    function setupNotificationChannel() {
        if (!channel) return () => { };

        const onNotificationEventName = `${channelName}-on-notification`;

        // On Notification
        channel.bind(onNotificationEventName, function (data) {
            const { user_id } = data;

            const currentUser = getCurrentLoggedinUser();
            // const whoseNotification = `${user_id}-${user_type}`;
            const whoseNotification = `${user_id}-end_user`;
            const ownNotification = whoseNotification === `${currentUser?.identifier}`;
            if (ownNotification) {
                refreshNotifications();
            }
        });

        return () => {
            channel.unbind(onNotificationEventName);
        }
    }

    useEffect(()=>{
        if(!moduleName) return
        setLoading(true)
        getNotifications(moduleName)
    },[moduleName]);

    useEffect(() => {
        refreshNotifications();
    }, [assetNotificationCount, contractNotificationCount])

    useEffect(() => {
        const channelCleanup = setupNotificationChannel();
        return () => {
            channelCleanup();
        }
    }, [channel])

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    function setDefaults(){
        setOpenAlertList(false)
        setModuleName('')
        setNotifications([])
        page.current = 0
    }

  return (
    <>
        <div className="p-4">
            <div className={`bell_icon_holder ${hasUnreadNotifications ? 'active' : ''}`} aria-describedby={id} variant="contained" onClick={handleClick}>
                <i className="icon ti ti-bell" />
            </div>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
            >
                <div className="p-12">
                    <ul className="list_popover_holder">
                        {lists.map((eachItem, index) => {
                            return (
                                <li key={index} onClick={handleClose}>
                                    <Link onClick={()=>handleOnClickMenuItem(eachItem.type) }>
                                        {eachItem.title}
                                        <span className="count_holder">
                                            <span className="number">{eachItem.count}</span>
                                        </span>
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                    <div className="mt-8">
                        <Button
                            className='primary_maroon_outlined_btn'
                            id="demo-customized-button"
                            aria-haspopup="true"
                            variant="contained"
                            onClick={()=>{
                                if(
                                    window.location.pathname === '/notification/contracts' ||
                                    window.location.pathname === '/notification/subscriptions' ||
                                    window.location.pathname === '/notification/iot'
                                ){
                                    router.push(window.location.pathname)
                                }else{
                                    router.push('/notification/assets')
                                }
                                setAnchorEl(null)
                            }}
                        >
                            Create Notification Alert
                        </Button>
                    </div>
                </div>
            </Popover>
        </div>
        {
            openAlertList && (
                <AddDashboardAlert
                    open={openAlertList}
                    close={setDefaults}
                    moduleName={moduleName}
                    notifications={notifications}
                    loading={loading}
                    markAllAsRead={markAllAsRead}
                    updateReadStatusOnClickNotification={updateReadStatusOnClickNotification}
                    getNotifications={()=>getNotifications(moduleName)}
                    onClickRefreshBtn={onClickRefreshBtn}
                />
            )
        }
    </>
  );
}
