import axios from '@fuse/utils/axios';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { makeStyles } from '@material-ui/core';
import React, { useState,useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import { countries } from '@fuse/utils/countryName'
import { Autocomplete } from '@material-ui/lab';
import Editor from '@fuse/components/Editor';
// import LocationTypeDialog from './QuickAddLocationType';
import { Stack, Box, Stepper, Step, StepButton, InputAdornment, Button, DialogTitle, Divider, Icon, IconButton, TextField, TextareaAutosize, Typography, Drawer, CardContent, Paper, Grid, Item, CircularProgress, Tooltip, Dialog,DialogContent, DialogActions } from '@mui/material'
import AlphabeticalSorting from '@fuse/utils/alphabeticalSort';
import {formatPhoneNumber} from '@fuse/utils/formatPhoneNumber'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))


const AddManufacturerFromQuickAction = ({
    handleOpenCloseAddModalEvent,
    openAddStatusModalStateData,
    newDataAddedEvent
}) => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const toast = useToast(dispatch)
    const [formattedNumber, setFormattedNumber] = useState('')

    const schema = yup.object({
        manufacturer_name: yup.string().trim().required('Manufacturer Name is required!'),
        url:yup.string().trim().required('URL is required!'),
        // phone:yup.string().when({
		// 	is: (val) => !!val,
		// 	then: yup.string().matches(/[0-9]{3}-[0-9]{3}-[0-9]{4}$/, 'Phone number should be in 000-000-0000 format'),
		// }),
    })

    useEffect(() => {
        if (openAddStatusModalStateData) {
            document.querySelector('.MuiDialogContent-root').scrollTop = 0
        }
    }, [openAddStatusModalStateData])


    const defaultValues = {
        manufacturer_name: '',
        url: '',
        phone: '',
        address_1: '',
        address_2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
    }

    function changeNumberFormat(e){
		setFormattedNumber(formatPhoneNumber(e.target.value))
	}

    const { 
        control, 
        formState:{errors}, 
        handleSubmit: manufactureFormSubmit, 
        reset: manufactureFormReset,
        trigger
    } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });


    const onSubmit = async(data) => {
        await trigger();
        if(Object.keys(errors).length !== 0) { 
            return;
        }
        if(data.url.indexOf('.') == -1){
            toast.error('Please enter a valid URL')
            return
        }
        if (formattedNumber && !/[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(formattedNumber)) {
			toast.error("Enter a valid Phone Number!")
			return
		}

        const payload = {
            entity_external_platform_id: String(Math.trunc(Math.random()*20000000)),
            manufacturer_name:data.manufacturer_name,
            phone_number:formattedNumber || '',
            address_line_1:data.address_1 || '',
            address_line_2:data.address_2 || '',
            city:data.city || '',
            state:data.state ||'',
            zip:data.zip || '',
            country:data.country.name || '',
            // url:"www."+formData.url,
			url: data.url.includes('www.') ? data.url : "www."+data.url,
        }

        console.log(payload)

        axios.post('/manufacturers/add', payload).then(response => {
            // response.data.data.new_manufacturer
            if(response.status == 201){
                toast.error(response.data.message)
                return
            }else {
                if(response.data.message == 'URL already exists'){
                  toast.error(response.data.message)
                  return
                }
                toast.success('Manufacturer has been created.')
                newDataAddedEvent(response.data.data.new_manufacturer)
                // manufactureFormReset(defaultValues)
                // setFormattedNumber('')

                //Send newly added manufacturer value
                // props.handleCloseDialog(response.data.data.new_manufacturer)
                // toast.success('Manufacturer added Successfully.')
                // props.refreshList()  
            }
        }).catch(err => {
            toast.error(err.response.data.message)
        })
    }

    return (
        <>
            <Dialog
                className="cstm_dialog_modal small text-center"
                open={openAddStatusModalStateData}
                // onClose={handleOpenCloseAddModalEvent}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <div className='heading_holder flexbox_holder items-center w-full'>
                        <h2 className="main_heading">Add Manufacturer</h2>
                        <Button className='transparent_icon_btn' onClick={handleOpenCloseAddModalEvent}>
                            <i className="icon ti ti-x" />
                        </Button>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className="text-left">
                        <div className='mb-10'>
                            <Typography className='status_text'>
                                URL
                                <span className='required_span'>*</span>
                            </Typography>
                            <Controller
                                name='url' 
                                control={control}
                                render={( { field} ) => (
                                <>
                                    <TextField 
                                        {...field}
                                        InputProps={{
                                            startAdornment: 
                                            <InputAdornment style={{color: "#1D2939",fontSize: "14px",marginRight:"0"}} disableTypography position="start">
                                                www.</InputAdornment>,
                                        }}
                                        variant='outlined'
                                        //label='URL' 
                                        fullWidth
                                        required
                                        style={{background: 'white'}}
                                        error={!!errors.url}
                                        helperText={errors?.url?.message} 
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                    />
                                </>
                                ) }
                            />
                        </div>
                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Manufacturer
                                <span className='required_span'>*</span>
                            </Typography>
                            <Controller
                                name='manufacturer_name' 
                                control={control}
                                render={( { field} ) => (
                                <>
                                    <TextField 
                                        {...field}
                                        variant='outlined'
                                        //label='URL' 
                                        fullWidth
                                        required
                                        style={{background: 'white'}}
                                        error={!!errors.manufacturer_name}
                                        helperText={errors?.manufacturer_name?.message} 
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                    />
                                </>
                                ) }
                            />
                        </div>
                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Phone
                            </Typography>
                            <Controller
                                name='phone' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                        {...field}
                                        value={formattedNumber}
                                        style={{background: 'white'}}
                                        onKeyPress={(event) => {
                                            if (!/[0-9/-]+/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onChange={(e)=>{
											changeNumberFormat(e)
										}}
                                        onPaste={(e)=>{e.preventDefault()}}
                                        variant='outlined'
                                        // label='Phone Number' 
                                        fullWidth
                                        error={!!errors.phone}
                                        helperText={errors?.phone?.message} 
                                    />
                                )}
                            />
                        </div>

                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Address 1
                            </Typography>
                            <Controller
                                name='address_1' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                        {...field}
                                        variant='outlined'
                                        // label='Address Line 1' 
                                        fullWidth 
                                        style={{background: 'white'}}
                                    />
                                ) }
                            />
                        </div>

                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Address 2
                            </Typography>
                            <Controller
                                name='address_1' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                        {...field}
                                        variant='outlined'
                                        // label='Address Line 1' 
                                        fullWidth 
                                        style={{background: 'white'}}
                                    />
                                ) }
                            />
                        </div>

                        <div className='mb-10'>
                            <Typography className='status_text'>
                                City
                            </Typography>
                            <Controller
                                name='city' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                        {...field}
                                        variant='outlined'
                                        // label='City' 
                                        fullWidth
                                        style={{background: 'white'}}
                                    />
                                )}
                            />
                        </div>

                        <div className='mb-10'>
                            <Typography className='status_text'>
                                State/Province
                            </Typography>
                            <Controller
                                name='state' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                        {...field}
                                        variant='outlined'
                                        // label='State/Province' 
                                        style={{background: 'white'}}
                                        fullWidth
                                    />
                                )}
                            />
                        </div>

                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Zip
                            </Typography>
                            <Controller
                                name='zip' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                        {...field}
                                        variant='outlined'
                                        // label='Zip' 
                                        style={{background: 'white'}}
                                        fullWidth
                                    />
                                )}
                            />
                        </div>

                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Country
                            </Typography>
                            <Controller
                                name="country"
                                className="mb-16"
                                control={control}
                                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                    <>
                                        <Autocomplete
                                            {...field}
                                            style={{ width: '100%' }}
                                            options={countries}
                                            // disablePortal
                                            onChange={(event, value) => field.onChange(value)}
                                            PaperComponent={({ children }) => (
                                                <Paper className="autocomplete_holder autocomplete_paper_holder">{children}</Paper>
                                            )}
                                            getOptionLabel={option => option.name ?? ''}
                                            renderInput={(params) => {
                                                return (
                                                    <div ref={params.InputProps.ref}>
                                                        <TextField
                                                            style={{background: 'white'}}
                                                            {...params}
                                                            // label="Country"
                                                            error={!!errors.country}
                                                            helperText={errors?.country?.message}
                                                            FormHelperTextProps={{
                                                                style: {marginLeft : 0}
                                                            }}
                                                            inputRef={ref}
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </div>
                                                )
                                            }}
                                        />
                                    </>
                                )}
                            />
                        </div>



                    </div>


                    
                    
                </DialogContent>
                <DialogActions className="action_footer_holder">
                    <Button onClick={handleOpenCloseAddModalEvent} className="secondary_btn">Cancel</Button>
                    <Button onClick={manufactureFormSubmit(onSubmit)} className="primary_btn">
                        Save and Select
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}


export default AddManufacturerFromQuickAction;