import { useEffect } from "react"
import { useDispatch } from 'react-redux';
import usePusher from '@fuse/hooks/usePusher';
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import { initPusher, updateUserCount, resetPusher } from 'app/store/fuse/pusherSlice';
import { Redirect } from "react-router";

const excludedRoutes = ['/login', '/registration','/reset/','/welcome', '/reset-password']

export const isExcludedRoute = (pathName = window.location.pathname) => {
    // return excludedRoutes.includes(pathName)
    let excluded = false;
        excludedRoutes.forEach(excludedRoute => {
			if(!excluded && pathName.includes(excludedRoute)){
				excluded = true;
			}
		})
		return excluded

}

export default function AuthCheckContainer(props) {
	const user = useCurrentUser().data;

	const isGuest = !user.token;

    if(isGuest && !isExcludedRoute()){
		<Redirect to={'/login'} />
		// if(window.location.pathname === '/'){
		// 	<Redirect to={'/login'} />
		// }else{
		// 	return <Redirect to={'/login'} />
		// }
    }

	
	return props.children;
}