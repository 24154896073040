import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import {
  Button,
  Grid,
  Card,
  Typography,
  Box,
  Tabs,
  Tab,
  Skeleton,
  Tooltip
} from "@mui/material";
import Payments from "./Tabs/Payments";
import PaymentMethod from "./Tabs/PaymentMethod";
import Incidents from "./Tabs/Incidents";
import Activities from "./Tabs/Activities";
import ServiceRequests from "./Tabs/service_requests";
import Assets from "./Tabs/Assets";
import Files from "./Tabs/Files";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "styles/ag-grid-header-style.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import axios from "@fuse/utils/axios";
import { generateProperLocationString } from "@fuse/utils/general";
import { useRouter } from "@fuse/hooks";
import { dateReformatTo_mmm_dd_yyyy, getFormattedDate, monthDiff } from '@fuse/utils/dateOperations'
import AddNotes from "@fuse/components/general/AddNotes";
import { formatPhoneNumber } from "@fuse/utils/formatPhoneNumber";
import { formatPriceFieldOnBlur } from "@fuse/utils/currencyFormat";
import { formatString } from "@fuse/utils/stringOperations";
import { setFromData } from '../store/formViewDialogReducer'
import { useDispatch } from "react-redux";
import QuickAddContract from "app/fuse-layouts/shared-components/QuickAddContract";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';

const ContractDetailsDailog = ({ }) => {
  const router = useRouter();
  const dispatch = useDispatch()
  const [contractDetails, setContractDetails] = useState({});
  const [contractProviderDetails, setContractProviderDetails] = useState({});
  const [shippingDetails, setShippingDetails] = useState({});
  const [paymentDetails, setPaymentDetails] = useState({});
  const [ billingCycleDetails, setBillingCycleDetails ] = useState(null);
  const [ paymentMethod, setPaymentMethod ] = useState(null);
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ contractFiles, setContractFiles ] = useState([])
  const [ isShareLinkCopied, setIsShareLinkCopied ] = useState(false)
	const [openAddNote, setOpenAddNote] = useState(false)
  const [ openAddContractForm, setOpenAddContractForm ] = useState(false);

  const contract_id = router.params.id

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        <Box sx={{ p: 3 }}>{children}</Box>
      </div>
    );
  }

  const setContractCurrentStatus = () => {
    if (!contractDetails?.contractEndDate) return;
    /*
     * Active- Contract End Date greater than 90 days
     * Expiring- Contract End Date in between 90 days
     * Expired - Contract End Date less than 90 days
    */
    if(Math.ceil((new Date(contractDetails?.contractEndDate).getTime() - new Date().getTime())/(1000*3600*24)) > 90){
      return { status: "Active", background: "green", icon: "done" };
    }else if(Math.ceil((new Date(contractDetails?.contractEndDate).getTime() - new Date().getTime())/(1000*3600*24)) <= 90 
      && Math.ceil((new Date(contractDetails?.contractEndDate).getTime() - new Date().getTime())/(1000*3600*24)) > 0){
      return { status: "Expiring", background: "grey", icon: "close" };
    }else if(Math.ceil((new Date(contractDetails?.contractEndDate).getTime() - new Date().getTime())/(1000*3600*24)) <= 0 ){
      return { status: "Expired", background: "grey", icon: "close" };
    }
  };

  const generateLocationName = (data) => {
    if (!data) return;
    const { address1, city, state, zip, country } = data;
    if (!address1 && !city && !state && !zip && !country) {
      return "-";
    }
    return `${data?.location_name ? data?.location_name + ", " : ""}${
      address1 ? address1 + ", " : ""
    }${city ? city + ", " : ""}${state ? state + ", " : ""}${
      zip ? zip + ", " : ""
    }${country ? country : ""}`;
  };

  const getContractDetails = () => {
    setLoading(true);
    axios.get(`/contracts/${contract_id}`).then((res) => {
      let contractData = res.data.data.contract;
      let assetData = res.data.data.assets;
      setContractDetails({
        contractName: contractData.contract_name,
        contractNumber: contractData.entity_external_platform_id,
        fullLengthContractNumber: contractData.entity_external_platform_id,
        id: contractData.id,
        sla: contractData?.service_level_agreement?.sla_name,
        total_contract_value: contractData.total_contract_value,
        contractStatus:
          contractData?.contract_header_status?.contract_status_name,
        contractStatusColor: contractData?.contract_header_status?.status_color,
        contractType: contractData?.contract_headers_type?.display_name,
        contractStartDate: contractData?.contract_start_date,
        contractEndDate: contractData?.contract_end_date,
        contractDescription: contractData?.contract_description,
        numberOfAssets: assetData.totalAssets,
        master: contractData.master === false ? "No" : "Yes",
        parentContract:
          contractData?.parent_contract?.entity_external_platform_id,
      });
      setAssets(!assetData?.listing ? [] : flattenAssets(assetData?.listing));
      setContractProviderDetails(contractData?.contract_service_provider);
      setPaymentDetails(contractData?.contract_payment);
      setShippingDetails(contractData?.shipping);
      setBillingCycleDetails(contractData?.subscription_billing);
      setPaymentMethod(contractData?.subscription_payment);

      const editData = {
				id: contractData.id,
				contract_number: contractData.entity_external_platform_id || '',
				total_contract_value: contractData.total_contract_value !== null ? contractData.total_contract_value : '',
				total_contract_value_: contractData.total_contract_value !== null ? formatPriceFieldOnBlur(contractData.total_contract_value) : '',
				contract_name: contractData.contract_name,
				company_name: contractData.user_company?.company_name || '',
				contract_description: contractData.contract_description,
				start_date: contractData.contract_start_date !== null ? dateReformatTo_mmm_dd_yyyy(contractData.contract_start_date) : "",
        		end_date: contractData.contract_end_date !== null ? dateReformatTo_mmm_dd_yyyy(contractData.contract_end_date) : "",
				type: contractData?.contract_headers_type?.display_name || '',
				status: contractData?.contract_header_status?.contract_status_name || '',
				number_of_assets: contractData?.assets?.length,
				// related_asset: related_asset,
				SLA: contractData?.service_level_agreement?.sla_name || '',
				source: formatString(contractData?.source) || "",
				service_provider: contractData?.contract_service_provider?.service_provider_name || '',
				master: contractData?.master === true ? "Yes" : "No",
				parent_contract: contractData?.parent_contract?.contract_name || '',
				['new/renew']: contractData?.renewal || '',
				location: contractData.shipping?`${contractData.shipping.location_name}-${contractData.shipping.address1}, ${contractData.shipping.city}, ${contractData.shipping.state}, ${contractData.shipping.country}`: "",
				location_id: contractData.shipping?.entity_external_platform_id || '',	
				payment_amount: contractData?.contract_payment?.payment_amount || '',
				currency: contractData?.contract_payment?.country || '',
				payment_term: contractData?.contract_payment?.payment_term?.display_name || '',
				payment_order_number: contractData?.contract_payment?.po_number || '',
				payment_order_date: contractData?.contract_payment?.po_date ? dateReformatTo_mmm_dd_yyyy(contractData?.contract_payment?.po_date) : null || '',
				reminder: formatString(contractData?.reminder) || '',
				text_contact_number: contractData?.support_contact_number || '',
				contract_status_object: contractData?.contract_header_status,
				parent_contract_object: contractData?.parent_contract,
				service_level_agreement_object: {
          id:contractData?.service_level_agreement.id,
          sla_name: contractData?.service_level_agreement.sla_name
        },
				service_provider_object: contractData?.contract_service_provider,
				contract_type_object: contractData?.contract_headers_type,
				location_object: contractData?.shipping,
				contract_payment_object: contractData?.contract_payment,
				master_object: { value: contractData?.master, label: contractData?.master ? 'Yes' : 'No' },
				amount: contractData.subscription_billing !== null ? contractData.subscription_billing?.amount || '' : '',
				billing_cycle: contractData.subscription_billing !== null ? `${contractData?.subscription_billing?.billing_cycle} ${contractData?.subscription_billing?.month || ''}` || '' : '',
				billing_cycle_: contractData.subscription_billing !== null ? contractData.subscription_billing?.billing_cycle : '',
				billing_cycle_date: contractData.subscription_billing !== null ? contractData?.subscription_billing?.billing_cycle_date?dateReformatTo_mmm_dd_yyyy(contractData.subscription_billing?.billing_cycle_date):'No Billing Cycle Date' : 'No Billing Cycle Date',
				
				po_date_exact: contractData.subscription_payment !== null ? contractData?.subscription_payment?.po_date || '' : '',
				po_date: contractData.subscription_payment !== null ? contractData.subscription_payment.po_date?dateReformatTo_mmm_dd_yyyy(contractData.subscription_payment.po_date): 'No PO Date' : 'No PO Date',
				po_number: contractData.subscription_payment !== null ? contractData.subscription_payment.po_number || 'No PO Number' : 'No PO Number',
				every: contractData.subscription_billing !== null ? contractData?.subscription_billing?.billing_cycle  || '1' : '1',
				currency: contractData.subscription_billing !== null ? contractData?.subscription_billing?.currency || '' : '',
				month: contractData.subscription_billing !== null ? contractData?.subscription_billing?.month || '' : '',
				payment_term_id: contractData.subscription_billing !== null ? contractData?.subscription_billing?.payment_term_id || '' : '',
				payment_term: contractData.subscription_billing !== null ? {
          id: contractData?.subscription_billing?.payment_term.id,
          display_name: contractData?.subscription_billing?.payment_term.display_name
        } || null : null,
				subscription_billing_id: contractData.subscription_billing !== null ? contractData?.subscription_billing?.id || '' : '',
				subscription_payment_id: contractData.subscription_payment !== null ? contractData?.subscription_payment?.id || '' : '',
				value: contractData.subscription_payment !== null ? contractData.subscription_payment.value : 'purchaseOrder',
				card_number: contractData.subscription_payment !== null ? contractData.subscription_payment.card_number || 'No Card Number' : 'No Card Number',
				card_holder_name:  contractData.subscription_payment !== null ? contractData?.subscription_payment.card_holder_name || 'No Card Holder Name' : 'No Card Holder Name',
				billing_cycle_date_exact: contractData.subscription_payment !== null ? contractData?.subscription_billing?.billing_cycle_date : '',
				manually_added: contractData?.manually_added,

				subscription_billing: contractData.subscription_billing !== null ? contractData.subscription_billing : null,
				subscription_payment: contractData.subscription_payment !== null ? contractData.subscription_payment : null,
				original_info:{
					contract_name: contractData.contract_name,
					contract_description: contractData.contract_description,
					contract_start_date: contractData.contract_start_date || "",
					contract_end_date: contractData.contract_end_date || '',
					contract_status: contractData?.contract_header_status?.contract_status_name || '',
					payment_amount: contractData?.contract_payment?.payment_amount || '',
					payment_term: contractData?.contract_payment?.payment_term?.display_name || '',
					service_provider_object: {
            id: contractData.contract_service_provider.id,
            service_provider_name: contractData.contract_service_provider.service_provider_name
          },				
					parent_contract: contractData?.parent_contract?.contract_name || '-',
					master: contractData?.master,
					contract_type: contractData?.contract_headers_type?.display_name || '',
					contract_status_object: contractData?.contract_header_status,
					renewal: contractData?.renewal || '',
					text_contact_number: contractData?.support_contact_number || '',
					payment_order_date: contractData?.contract_payment?.po_date || '',
					reminder: contractData?.reminder || '',
					service_level_agreement_object: contractData?.service_level_agreement,
					payment_term_obj: contractData?.contract_payment?.payment_term,
					location_id: contractData.shipping?.entity_external_platform_id || '',
					payment_order_number: contractData?.contract_payment?.po_number || '',
					location_object: contractData?.shipping,
					parent_contract_object: contractData?.parent_contract,
					remind_me_before: contractData?.remind_me_before,
					notification_type_name: contractData?.notification_type_name
				},
				source_: contractData.source == 'manual'? 'Manual' :  contractData.source == 'CSV Upload'? 'CSV': ''
			}

      dispatch(setFromData(editData))
    }).catch((err) => {
      console.log(err)
      if(err?.response?.data?.message === "Invalid request params input" || 
        err?.response?.data?.message === "Contract not found"){
        router.push('/not-found')
      }
    }).finally(()=>{
      setLoading(false)
    })
  };

  const flattenAssets = (data) => {
    if (!data) return [];
    return data.map((item) => ({
      id: item?.id,
      serial_number: item?.serial_number,
      user_name: item?.assigned_user
        ? `${item?.assigned_user?.first_name} ${item?.assigned_user?.last_name}`
        : "Unassigned",
      model: item?.asset_model?.model_name,
      manufacturer: item?.asset_model?.manufacturer?.manufacturer_name,
      category: item?.asset_model?.category?.category_name,
      location: generateLocationName(item?.shipping),
      asset_status: item?.asset_status?.status_name,
      asset_status_color: item?.asset_status?.status_color,
    }));
  };

  function onFilterChanged(event) {
    const rows = event.api.getFilterModel();
    // validation for no-overlay
    const total_rows = event.api.getDisplayedRowCount();
    if (total_rows === 0) {
      event.api.showNoRowsOverlay();
    } else {
      event.api.hideOverlay();
    }
  }

  const setHeadersRow = (data) => {
    if (!data || data.length === 0) return [];
    return Object.keys(data[0]);
  };

  function fetchFiles(contract_id){
    if(!contract_id) return
    axios.get(`/contracts/get-contract-files/${contract_id}`).then((res)=>{
        const { files } = res.data.data;
        setContractFiles(files)
    }).catch((err)=>{   
        console.log(err);
    })
  }

  function handleEdit(){
    setOpenAddContractForm(true)
  }

  function handleAddContractFormClose (data) {
		setOpenAddContractForm(false)
    getContractDetails();
	}

  useEffect(() => {
    if (!contract_id) return setContractDetails({});
    getContractDetails();
    fetchFiles(contract_id)
    return () => {
      dispatch(setFromData(null))
    } 
  }, [contract_id]);

  function shareContract(contract_id) {
    let link = `${window.location.origin}/contracts/${contract_id}`
    navigator.clipboard.writeText(link);
    if (!isShareLinkCopied) {
      setIsShareLinkCopied(true); // show tooltip
      setTimeout(() => {
        setIsShareLinkCopied(false); // remove/hide tooltip
      }, 2000);
    }
  }

  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
      setValue(newValue)
  }

  const handleOpenAddNote = () => {
		setOpenAddNote(true)
	}

  const handleCloseAddNote = (updatedDescription="") => {
		setOpenAddNote(false)
    if(typeof updatedDescription === "string"){
      setContractDetails({
        ...contractDetails,
        contractDescription: updatedDescription
      })
    }
	}

  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: '#D1FADF',
            color: '#027A48',
            fontSize: '12px',
            fontWeight: 500
          },
        },
      },
    },
  });
  return (
    <div style={{background: '#fff', marginBottom:'15px', padding: '28px'}}>
      <Link className="back_btn_icon" onClick={()=>router.back()}>
          <i className='ti ti-arrow-left' />
      </Link>
      <>
        <div className='inner_details_section'>
          <div className='flexbox_holder' style={{ alignItems: 'flex-start'}}>
            <div className='left_holder' style={{ width: 'calc(100% - 484px)'}}>
              <div className='content_holder align-top pe-40'>  
                <div className='status_badge_holder'>
                  <span style={{
                      backgroundColor: setContractCurrentStatus()
                      ?.background
                  }} className='dot_holder' />   
                  <span>Contract Status:</span>
                  <span style={{ display: "inline-block", paddingLeft: '4px', textTransform: 'capitalize' }}>
                      {setContractCurrentStatus()?.status}
                  </span>
                </div>

                <div className='name_loc_info_holder'>
                  <div className='flex flex-wrap items-center'>
                    <div className='flex flex-wrap items-center'>
                      <Typography variant="h6" className='main_page_heading' component="div">
                        {contractDetails?.contractName ?? 'N/A'}
                      </Typography>
                      <span className='orange_badge_holder'>{contractDetails?.contractNumber ?? 'N/A'}</span>
                      <div style={{marginLeft: '4px'}}>
                        <Button
                            variant="contained"
                            className='share_btn'
                            style={{ background: isShareLinkCopied ? '#90EE90' : '#F2F4F7'}}
                            onClick={()=>shareContract(contract_id)}
                        >
                          {isShareLinkCopied &&
                            <ThemeProvider theme={theme}>
                            <Tooltip title={'Copied'} placement="bottom">
                                <i className='ti ti-copy' />
                            </Tooltip>
                            </ThemeProvider>
                          }
                            {/* {!isShareLinkCopied && <i className='ti ti-share' />} */}
                            {!isShareLinkCopied && <i className='ti ti-copy' />}
                            {/* <Icon className="text-white mr-5" fontSize="default"><span className="material-symbols-outlined">{isShareLinkCopied ? 'file_copy' : 'share'}</span></Icon> */}
                            {/* {isShareLinkCopied ? 'Copied' : 'Share'} */}
                        </Button>
                        <Button
                            variant="contained"
                            className='share_btn'
                            style={{ background: isShareLinkCopied ? '#90EE90' : '#F2F4F7'}}
                            onClick={handleEdit}
                        >
                            <i className='ti ti-pencil' />
                        </Button>
                      </div>
                    </div>
                    <p className='asset_address_text'>
                      <i className='ti ti-map-pin' />
                      {generateProperLocationString({
                        location_name: shippingDetails?.location_name ?? '',
                        address1: shippingDetails?.address1 ?? '',
                        address2: shippingDetails?.address2 ?? '',
                        city: shippingDetails?.city ?? '',
                        state: shippingDetails?.state ?? '',
                        country: shippingDetails?.country ?? '',
                        zip:  shippingDetails?.zip ?? ''
                      })}
                    </p>
                  </div>
                </div>

                <div className='infoStat_card_holder'>
                  <div className='each_card'>
                    <p className='label_holder'>Contract Number</p>
                    <p className='name_holder'>{contractDetails?.contractNumber ?? 'N/A'}</p>                      
                  </div>
                  <div className='each_card'>
                    <p className='label_holder'>Contract Type</p>
                    <p className='name_holder'>{contractDetails?.contractType ?? 'N/A'}</p>                      
                  </div>
                  <div className='each_card'>
                    <p className='label_holder'>Number of Assets</p>
                    <p className='name_holder'>{contractDetails?.numberOfAssets ? Number(contractDetails?.numberOfAssets).toLocaleString() : 0}</p>                      
                  </div>
                  <div className='each_card'>
                    <p className='label_holder'>Service Level Agreement(SLA)</p>
                    <p className='name_holder'>{contractDetails?.sla ?? 'N/A'}</p>                      
                  </div>
                  <div className='each_card'>
                    <p className='label_holder'>Start Date</p>
                    <p className='name_holder'>{contractDetails?.contractStartDate ? dateReformatTo_mmm_dd_yyyy(contractDetails.contractStartDate) : 'N/A'}</p>                      
                  </div>
                  <div className='each_card'>
                    <p className='label_holder'>End Date</p>
                    <p className='name_holder'>{contractDetails?.contractEndDate ? dateReformatTo_mmm_dd_yyyy(contractDetails.contractEndDate) : 'N/A'}</p>                      
                  </div>
                  <div className='each_card'>
                    <p className='label_holder'>Master</p>
                    <p className='name_holder'>{contractDetails?.master ?? 'N/A'}</p>                      
                  </div>
                  <div className='each_card'>
                    <p className='label_holder'>Contract Tag</p>
                    <p className='name_holder'>{contractDetails?.contractStatus ?? 'N/A'}</p>                      
                  </div>
                </div>
              </div>
            </div>

            <div style={{maxHeight: '100%', width: '484px', maxWidth: '484px'}} className='map_location_holder'>
              { loading ? 
                <>
                  <Skeleton width={484} height={272} style={{ transform: 'none' }} />
                </> : 
                <>
                        <div>
                          <iframe
                            width="100%"
                            height="272"
                            src={`https://maps.google.com/maps?q=${encodeURIComponent(
                              [
                                shippingDetails?.address1,
                                shippingDetails?.address2,
                                shippingDetails?.city,
                                shippingDetails?.state,
                                shippingDetails?.zip,
                                shippingDetails?.country,
                              ]
                                .filter((item) => item)
                                .join(",")
                            )}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
                            frameBorder="0"
                            scrolling="no"
                            marginHeight="0"
                            marginWidth="0"
                          ></iframe>
                        </div>
                </>
              }
            </div>
          </div>
        </div>

        <div style={{paddingTop: '56px'}}>
          <Box sx={{ display: 'inline-block', backgroundColor: '#F2F4F7', padding: '8px', borderRadius: '8px' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="category_tabs">
              <Tab className='tabs_exampleText' label="Service Provider Info" />
              <Tab className='tabs_exampleText' label="Notes" />
              <Tab className='tabs_exampleText' label={`Assets (${contractDetails?.numberOfAssets})`} />
              <Tab className='tabs_exampleText' label="Billing Cycle" />
              <Tab className='tabs_exampleText' label="Payment Method" />
              <Tab className='tabs_exampleText' label="Insights" />
              <Tab className='tabs_exampleText' label="Service Tickets" />
              <Tab className='tabs_exampleText' label={`Files (${contractFiles.length})`} />
              <Tab className='tabs_exampleText' label="Activities" />
            </Tabs>
          </Box>  

          <div className='tab_scrollContent'>
            {value === 0 && <CustomTabPanel className='custom_tab' id='tab-0'>
              <div className='cstm_aggridtable_holder'>
                <table style={{maxWidth: '650px'}}>
                  <thead>
                    <tr>
                      <th>Attributes</th>
                      <th style={{width: '350px'}}>Value</th>
                    </tr>
                  </thead>
                  <tbody>                                        
                    <tr>
                      <td>Service Provider Name</td>
                      <td>{contractProviderDetails?.service_provider_name}</td>
                    </tr>
                    <tr>
                      <td>Website</td>
                      <td>{contractProviderDetails?.url}</td>
                    </tr>
                    <tr>
                      <td>Address</td>
                      <td>{Boolean(contractProviderDetails?.address1) ? contractProviderDetails?.address1 : 'N/A'}</td>
                    </tr>
                    <tr>
                      <td>Phone Number</td>
                      <td>{contractProviderDetails?.phone_number ? formatPhoneNumber(contractProviderDetails.phone_number) : 'N/A'}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CustomTabPanel>}
            {value === 1 && <CustomTabPanel className='custom_tab' id='tab-1'>
              <div className='notes_holder'>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                  <Button className='button primary_maroon_outlined_btn mb-10' onClick={() => handleOpenAddNote()}>
                      <i className="icon ti ti-pencil" />
                      Edit
                  </Button>
                </div>
                {
                  Boolean(contractDetails.contractDescription) ? <div dangerouslySetInnerHTML={{__html: contractDetails.contractDescription}}/>:
                  <div>No Data Found</div>
                }
            </div>
            </CustomTabPanel>}
            {value === 2 && <CustomTabPanel className='custom_tab' id='tab-2'>
              <Assets 
                allAssets={assets}
                getContractDetails={getContractDetails}
                contractDetails={contractDetails}
              />
            </CustomTabPanel>}
            {value === 3 && <CustomTabPanel className='custom_tab' id='tab-3'>
              <Payments billingCycleData={billingCycleDetails} />
            </CustomTabPanel>}
            {value === 4 && <CustomTabPanel className='custom_tab' id='tab-4'>
              <PaymentMethod paymentMethodData={paymentMethod} />
            </CustomTabPanel>}
            {value === 5 && <CustomTabPanel className='custom_tab' id='tab-5'>
              <Incidents contract_id={contractDetails.id}/>
            </CustomTabPanel>}
            {value === 6 && <CustomTabPanel className='custom_tab' id='tab-6'>
              <ServiceRequests allAssets={assets} />
            </CustomTabPanel>}
            {value === 7 && <CustomTabPanel className='custom_tab' id='tab-7'>
              <Files
                contract_id={contractDetails.id}
                contractFiles={contractFiles}
                fetchFiles={fetchFiles}
              />
            </CustomTabPanel>}
            {value === 8 && <CustomTabPanel className='custom_tab' id='tab-8'>
              <Activities contract_id={contractDetails.id} />
            </CustomTabPanel>}
          </div>
        </div>
      </>

      {
        openAddNote && <AddNotes
          open={openAddNote}
          close={handleCloseAddNote}
          content={contractDetails.contractDescription}
          module="contract"
        />
      }

      {
        openAddContractForm && <QuickAddContract open={ openAddContractForm } close={handleAddContractFormClose} />
      }
    </div>
  );
};

export default ContractDetailsDailog;
