import EndUsersAccountsTable from './tables';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import DynamicFieldsHeader from './header';
import FuseLoading from '@fuse/core/FuseLoading';
import { useDispatch } from 'react-redux';
import CsvUpload from './CsvUpload';
import axios from "@fuse/utils/axios";
import useRouter from '@fuse/hooks/useRouter';
import AddLocationDialog from './addLocationDialog';
import lodash from "lodash"
import { usePermission } from '@fuse/hooks';
import useToast from '@fuse/hooks/useToast';
import reducer from '../store';
import withReducer from 'app/store/withReducer';
// import SaveReportDialog from './saveReportDialog';
import { Tab, Tabs } from '@material-ui/core';
import { Menu, MenuItem } from '@material-ui/core';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';
import { usePusher } from '@fuse/hooks';
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import ActivityAgGridListing from '@fuse/components/ActivitieLogs/activitiesAgGridListing';
import SaveReportDialog from '../../reports/components/saveReportDialog';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
	layoutRoot: {},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	layoutRoot: {
		background:'#fcfcfc'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentCard:{
		marginBottom:"15px",
		borderRadius:"5px"
	}
});

const views = ['List View','Grid View'];
const defaultFilters = {
	quote_indicator:'',
	revenue: '',
	quote_status: '',
	agent: ''
}

function CardedFullWidth2TabbedSample(props) {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedView, setSelectedView] = useState(0);
	const [viewMenuOpened, setViewMenuOpened] = useState(false);
	const [filters, setFilters] = useState(defaultFilters);
	const [anchorEl, setAnchorEl] = useState(null);
	const [endUsersAccounts, setEndUsersAccounts] = useState([]);
	const [loading1, setLoading1] = useState(false);
	const router = useRouter();
	const agents = getAgents(endUsersAccounts);
	const [logs,setLogs] = useState([]);
	const [use,setUse] = useState(true);
	const { hasPermission } =usePermission();
	const addLocationPermission =hasPermission('add-location');
	const viewLocationPermission = hasPermission('view-location');
	const updateLocationPermission = hasPermission('update-location');
	const deleteLocationPermission = hasPermission('delete-location');
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const {broadcastChannel} = usePusher()
	const new_incident_event = "permission_change"
	const user = useCurrentUser();
	const dialogState = useSelector(({assetsApp}) => assetsApp.reportViewDialogReducer);

	// function handleViewMenuOpen(event) {
	// 	setAnchorEl(event.currentTarget);
	// 	setViewMenuOpened(true);
	// }

	function handleViewMenuClose() {
		setAnchorEl(null);
		setViewMenuOpened(false);
	}

	function getAgents(endUsersAccounts){
		const agentSet = new Set();
		for(let i = 0; i < endUsersAccounts.length ; i++){
			agentSet.add(endUsersAccounts[i].Agent);
		}
		return Array.from(agentSet);
	}

	function search(query) {
		if(query == ''){
			setEndUsersAccounts(endUsersAccounts);	
		}else{
			setEndUsersAccounts(endUsersAccounts.filter(acc=>{
				let flag = false;
				let values = Object.keys(acc);
				for(let i = 0; i < values.length; i++){
					if(String(acc[values[i]]).toLowerCase().includes(query)){
						flag = true;
					}
				}
				return flag;
			}))
		}
	}

	// function applyFilters(filters){
	// 	setFilters(filters);
	// 	setEndUsersAccounts(endUsersAccounts.filter(acc=>{
	// 		let flag = true;
	// 		if(filters.agent != '' && filters.agent != acc.Agent){
	// 			flag = false;
	// 		}
	// 		return flag;
	// 	}))
	// }

	function clearFilters() {
		setFilters(defaultFilters);
		setEndUsersAccounts(endUsersAccounts);
	}

	const handleTabChange = (event, value) => {
		if(value ==1){
			// getLogs()
			setUse(false);
		}else{
			setUse(true);
		}
		setSelectedTab(value);
	};
	
	// function toggleView(val){
	// 	setSelectedView(val);
	// }

	function gotoDetailsPage(id){
		router.push(`/locations/${id}`)
	}

	const [platformModule, setPlatformModule] = useState({});
	const [platformModuleAttributes, setPlatformModuleAttributes] = useState([]);

	async function getContracts(){
		// if(!viewPermission){
		// 	return;
		// }
		try {
			try {
				const res = await axios.get("/shipping/fetch-all");
				const { data } = res.data;
				setEndUsersAccounts(formatContracts(data));
			} catch (err) {
				console.log(err);
			}
		} finally {
			setLoading1(false);
		}
	}
	
	function search(query,field) {
		// if(!viewPermission){
		// 	toast.error("You don't have the view permission")
		// 	return;
		// }
		if(!query) return;

		setLoading1(true);

		axios.get("/shipping/search-shipping-locations",{
			params: {
				text:query,
				field:field
			}
		}).then((res) => {
			const { data } = res.data;
			console.log(data);
			setEndUsersAccounts(formatContracts(data))
		}).catch((err) => {
			console.log(err)
			setEndUsersAccounts([])
		}).finally(() => {
			setTimeout(()=>{
				setLoading1(false);
			},1000)
			
		})
	}

	function handleSearchReset(){
		setLoading1(true);
		getContracts();
	}

	function formatContracts(data){
		return data.map((contract) => {

			const dynamicAttributes = Object.entries(contract.dynamic_attributes).reduce((acc, [key, item]) => {
				acc[key] = item.attribute_value
				return acc;
			},{});
			const tempElement = document.createElement('div');
			tempElement.innerHTML = contract.note;

			return {				
				location_code: contract.entity_external_platform_id,
				location_name:contract.location_name,
				company_name: contract.user_company?.company_name,
				address_1:contract.address1,
				address_2:contract.address2,
				city:contract.city,
				state:contract.state,
				zip:contract.zip,
				["state/province"] : contract.state,
				country:contract.country,
				note_as_plain_text: tempElement.textContent,
				note: tempElement.textContent,
				location_type: contract.location_type,
				location_type_name: contract.location_type?.name ?? 'No Location Type',
				no_of_assets: contract.assets.length,
				...lodash.omit(contract, [
					"user_companies_id",
					"user_companies_users_id",
					"user_company",
					"user_companies_user",
					"dynamic_attributes",
					"user_id",
					"platform_customer_id",
					"created_at",
					"updated_at",
					"entity_external_platform_id",
					"company_id",
					"address1",
					"address2",
				]),
				...dynamicAttributes,
				source: contract.source || 'No Source'
			}
		})
	}

	async function getDynamicAttributes(){
		try {
			const res = await axios.get("/dynamic-attributes/fetch-all");
			const { data } = res.data;
			const platformModuleItem = data.find(({ name }) => name == "shipping");
			setPlatformModule(platformModuleItem);
			try {
				const res_1 = await axios.get(`/dynamic-attributes/fetch/${platformModuleItem.id}`);
				const { data: data_1 } = res_1.data;
				setPlatformModuleAttributes(data_1);
				return data_1;
			} catch (err) {
				toast.error("Unable to fetch dynamic attributes");
				console.log(err);
			}
		} catch (err_1) {
			toast.error("Unable to fetch dynamic attributes");
			setPlatformModule({});
			console.log(err_1);
		}
	}

	function refreshList(){
		getDynamicAttributes().then(() => {
			return getContracts();
		})
	}

	// function getImportList(){
	// 	axios.get('/logs/shipping')
	// 	.then(({data})=>{
	// 		setLogs(data.data);
	// 	})
	// 	.catch(err=>{
	// 		console.log('cannot fetch logs');
	// 	})
	// }

	function callToast(success,error,value){
		if(success){
			toast.success(value)
		}
		if(error){
			toast.error(value);
		}
	}

	// function formatLogs(data){
	// 	let length = data.length
	// 	return data.map((item)=>{
	// 		const shipObject = {
	// 			id:item.id,
	// 			action_id: `act${length}`,
	// 			action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
	// 			location_name : item?.shipping?.location_name || item.location_name,
	// 			name: item.user_name,
	// 			action: item.action_name,
	// 			description: item.comment,
	// 			previous_value: item.previous_data,
	// 			current_value: item.current_data,
	// 			field_name: item.action_field_name,
	// 			user_role: item.user_role_name,
	// 		}
	// 		length--
	// 		return shipObject
	// 	})
	// }

	// function getLogs(){
	// 	axios.get('/shipping/activities')
	// 	.then(({data})=>{
	// 		setLogs(formatLogs(data.data.activities));
	// 	})
	// 	.catch(err=>{
	// 		console.log('cannot fetch logs');
	// 	})
	// }

	useEffect(()=>{
		setLoading(true)
		getDynamicAttributes().then(() => {
			return getContracts();
		}).finally(() => {
			setLoading(false)
		});
	},[]);

	useEffect(()=>{
		if(broadcastChannel/* && invoices.length*/){
			broadcastChannel.bind(new_incident_event, data => {
				if(data.user_companies_id == user.data.user_companies_id){
					location.reload();
				}
			})
			return () => broadcastChannel.unbind(new_incident_event)
		}
	},[broadcastChannel])

	if (loading) {
		return(<FuseLoading />)
	} else {
		return (<>
			<CsvUpload refresh={refreshList}/>
			{dialogState.open && <SaveReportDialog tableName={"location"}/>}
			<FusePageCarded
				classes={{
					root: classes.layoutRoot,
					toolbar: 'p-0',
					topBg: classes.topBg,
					contentCard:classes.contentCard
				}}
				// header={
				// 	<DynamicFieldsHeader search={search} clearSearch={clearFilters} handleSearchReset={handleSearchReset} disable = {use} />
				// }
				// contentToolbar={
				// 	<>
				// 		<Tabs
				// 			value={selectedTab}
				// 			onChange={handleTabChange}
				// 			indicatorColor="primary"
				// 			textColor="primary"
				// 			variant="scrollable"
				// 			scrollButtons="off"
				// 			className="w-full h-64"
				// 		>
				// 			<Tab key="end_user_tab_1" className="h-64" label="All Locations" />
				// 			{viewLocationPermission && <Tab key="end_user_tab_2" className="h-64" label="Activities" />}
				// 		</Tabs>
				// 		<div>
				// 			<Menu
				// 				id="simple-menu"
				// 				anchorEl={anchorEl}
				// 				keepMounted
				// 				open={viewMenuOpened}
				// 				onClose={handleViewMenuClose}
				// 			>
				// 				{	
				// 					views.map((view,index) => {
				// 						return <MenuItem key={`view_${index}`} value={index} onClick={ev => {setSelectedView(ev.target.value);handleViewMenuClose()}}>{view}</MenuItem>
				// 					})
				// 				}
				// 			</Menu>
				// 		</div>
				// 	</>
				// }
				content={
					<>
						<div className={selectedTab !== 0 ? 'hidden' : ''}>
							{
								loading1 && <FuseLoading />
							}
							{
								!loading1 && 
								<EndUsersAccountsTable 
									endUsersAccounts={endUsersAccounts}
									selectedView={selectedView}
									gotoDetailsPage={gotoDetailsPage}
									refresh={refreshList}
									addLocationPermission = {addLocationPermission}
									viewLocationPermission = {viewLocationPermission}
									updateLocationPermission = {updateLocationPermission}
									deleteLocationPermission = {deleteLocationPermission}
								/>
							}
						</div>
						< AddLocationDialog getContracts={getContracts}/>
						<div className={selectedTab !== 1 ? 'hidden' : 'p-10'}>
							{/* <ActivitieLogs logs = {logs} /> */}
							{/* {Boolean(logs.length) ? <ActivityAgGridListing module='shipping' logs={logs}/> : <FuseLoading/> } */}
						</div>
					</>
				}
				innerScroll
			/>		
		</>)
	}
}

export default withReducer('shippingsApp', reducer)(CardedFullWidth2TabbedSample);

