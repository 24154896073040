import { React } from 'react';

export default function Iot() {

  return (
    <>
      <div className='custom_tab'>
    </div>
    </>
  );
}
