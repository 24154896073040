import FuseUtils from '@fuse/utils/FuseUtils';
import axios from '@fuse/utils/axios';
import { token } from '@fuse/utils/axios';
import axios2 from 'axios';
import jwtDecode from 'jwt-decode';
import cookie from 'react-cookies';
// import {persistor} from '../../store';
import * as Sentry from "@sentry/react";

class JwtService extends FuseUtils.EventEmitter {
	init() {
		this.setInterceptors();
		this.handleAuthentication();
	}

	setInterceptors = () => {
		axios.interceptors.response.use(
			response => {
				return response;
			},
			err => {
				return new Promise((resolve, reject) => {
					if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
						// if you ever get an unauthorized response, logout the user
						this.emit('onAutoLogout', 'Invalid access_token');
						this.setSession(null);
					}
					throw err;
				});
			}
		);
	};

	handleAuthentication = () => {
		const access_token = this.getAccessToken();

		if (!access_token) {
			this.emit('onNoAccessToken');

			return;
		}

		if (this.isAuthTokenValid(access_token)) {
			this.setSession(access_token);
			this.emit('onAutoLogin', true);
		} else {
			this.setSession(null);
			this.emit('onAutoLogout', 'access_token expired');
		}
	};

	createUser = data => {
		return new Promise((resolve, reject) => {
			axios.post('/api/auth/register', data).then(response => {
				if (response.data.user) {
					this.setSession(response.data.access_token);
					resolve(response.data.user);
				} else {
					reject(response.data.error);
				}
			});
		});
	};

	getBrowserName = () => {
		// // Opera 8.0+
		// if((!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0){
		// 	return 'Opera 8.0+';
		// }

		// // Firefox 1.0+
		// if(typeof InstallTrigger !== 'undefined'){
		// 	return 'Firefox 1.0+';
		// }

		// // Safari 3.0+ "[object HTMLElementConstructor]" 
		// if(/constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification))){
		// 	return 'Safari 3.0+';
		// }

		// // Internet Explorer 6-11
		// if(/*@cc_on!@*/false || !!document.documentMode){
		// 	return 'Internet Explorer 6-11';
		// }

		// // Edge 20+
		// if(!!window.StyleMedia){
		// 	return 'Edge 20+';
		// }

		// // Chrome 1 - 71
		// if(!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)){
		// 	return 'Chrome 1 - 71';
		// }

		// return 'Unknown';
		var navUserAgent = navigator.userAgent;
		var browserName  = navigator.appName;
		var browserVersion  = ''+parseFloat(navigator.appVersion); 
		var tempNameOffset,tempVersionOffset,tempVersion;


		if ((tempVersionOffset=navUserAgent.indexOf("Opera"))!=-1) {
			browserName = "Opera";
			browserVersion = navUserAgent.substring(tempVersionOffset+6);
			if ((tempVersionOffset=navUserAgent.indexOf("Version"))!=-1) 
				browserVersion = navUserAgent.substring(tempVersionOffset+8);
		} else if ((tempVersionOffset=navUserAgent.indexOf("MSIE"))!=-1) {
			browserName = "Microsoft Internet Explorer";
			browserVersion = navUserAgent.substring(tempVersionOffset+5);
		} else if ((tempVersionOffset=navUserAgent.indexOf("Chrome"))!=-1) {
			browserName = "Chrome";
			browserVersion = navUserAgent.substring(tempVersionOffset+7);
		} else if ((tempVersionOffset=navUserAgent.indexOf("Safari"))!=-1) {
			browserName = "Safari";
			browserVersion = navUserAgent.substring(tempVersionOffset+7);
			if ((tempVersionOffset=navUserAgent.indexOf("Version"))!=-1) 
				browserVersion = navUserAgent.substring(tempVersionOffset+8);
		} else if ((tempVersionOffset=navUserAgent.indexOf("Firefox"))!=-1) {
			browserName = "Firefox";
			browserVersion = navUserAgent.substring(tempVersionOffset+8);
		} else if ( (tempNameOffset=navUserAgent.lastIndexOf(' ')+1) < (tempVersionOffset=navUserAgent.lastIndexOf('/')) ) {
			browserName = navUserAgent.substring(tempNameOffset,tempVersionOffset);
			browserVersion = navUserAgent.substring(tempVersionOffset+1);
			if (browserName.toLowerCase()==browserName.toUpperCase()) {
				browserName = navigator.appName;
			}
		}

		// trim version
		if ((tempVersion=browserVersion.indexOf(";"))!=-1)
			browserVersion=browserVersion.substring(0,tempVersion);
		if ((tempVersion=browserVersion.indexOf(" "))!=-1)
			browserVersion=browserVersion.substring(0,tempVersion);

		return browserName
	}

	signInWithEmailAndPassword = async (email, password, remember) => {
		
		let ipAddress = null;
		let loginInfo = null;
		try{
			const apiResponse = await axios2.get('https://api.ipify.org/?format=json');
			loginInfo = apiResponse;
			if(apiResponse.data != undefined) {
				ipAddress = apiResponse.data.ip;
			} else {
				ipAddress = "{{auto}}";
			}
		}catch(err){
			console.log(err)
		}
		const browser = this.getBrowserName();
		Sentry.setExtra(null);
		// const dateString = new Date().toISOString()
		// const timeString = new Date().toTimeString()
		// const date = dateString.split("T")[0].split("-")
		// const time = timeString.split(" ")

		return new Promise((resolve, reject) => {
			axios
				.post('/auth/login', {
					email,
					password,
					client_ip: loginInfo?.data?.ip ?? null,
					// login_date_time: `${date[1]}-${date[2]}-${date[0]} ${time[0]}`,
					login_time: new Date(),
					browser:browser,
					domain_name: window.location.host
				})
				.then(response => {
					// console.log(response);
					if(response.status === 201){
						
						reject(response.data.message)
					}
					else{
						if (response.data.data.user) { 
							if(remember){
								localStorage.setItem('jwt_access_token', response.data.data.token);
							}
							window.localStorage.setItem('endUserLoginLogId', response.data.data.endUserLoginLogId)
							window.localStorage.setItem('LOGIN', Date.now().toString())
							window.localStorage.removeItem('LOGIN');
							this.setSession(response.data.data.token);
							// response.data.data.user.token = response.data.data.token;
							axios.defaults.headers.common.Authorization = `Bearer ${response.data.data.token}`;
							const user = {data:response.data.data.user,role:[response?.data?.data?.user?.roles_end_user?.display_name]};
							// console.log(response.data.data.user);
							Sentry.configureScope(function (scope) {
								scope.setLevel("error");
							});
							Sentry.setTag("email", email);
							Sentry.setTag("displayName", response.data.data.user.displayName);
							Sentry.setUser({
								"ip_address": ipAddress
							})
							resolve(user);
						} else {
							reject(response.data.data.error);
						}
					}
					// console.log(response)
					
				})
				.catch(err => {
					reject(err);
				});
		});
	};

	signInWithGoogle = async(idToken, google_id)=>{
		let ipAddress = null;
		let loginInfo = null;
		try{
			const apiResponse = await axios2.get('https://api.ipify.org/?format=json');
			loginInfo = apiResponse;
			if(apiResponse.data != undefined) {
				ipAddress = apiResponse.data.ip;
			} else {
				ipAddress = "{{auto}}";
			}
		}catch(err){
			console.log(err)
		}
		const browser = this.getBrowserName();
		Sentry.setExtra(null);
		return new Promise((resolve, reject) => {
			axios.post('/auth/google-login',{
				idToken, 
				google_id,
				login_time: new Date(),
				client_ip: loginInfo?.data?.ip ?? null,
				browser: browser,
				domain_name: window.location.host
			}).then(response =>{
				console.log('response', response)
				//console.log('Google signin success', response);
				if(response.status === 201){
					reject(response.data.message)
				}
				else{
					this.setSession(response.data.data.token);
					response.data.data.user.token = response.data.data.token;
					window.localStorage.setItem('endUserLoginLogId', response.data.data.endUserLoginLogId)
					window.localStorage.setItem('LOGIN', Date.now().toString())
					window.localStorage.removeItem('LOGIN');
					axios.defaults.headers.common.Authorization = `Bearer ${response.data.data.token}`;
					const user = {data:response.data.data.user,role:[response.data.data.user.roles_end_user.display_name]};
					Sentry.configureScope(function (scope) {
						scope.setLevel("error");
					});
					Sentry.setTag("email", response.data.data.user.email);
					Sentry.setTag("displayName", response.data.data.user.displayName);
					Sentry.setUser({
						"ip_address": ipAddress
					})
					if(user){
						resolve(user);
					}	
					else{
						reject(response.data.data.message)
					}
				}
					
			}).catch(err=>{
				console.log(err);
			})
		});
	}
	signInWithMicrosoft = async(uniqueId, username)=>{
		let ipAddress = null;
		let loginInfo = null;
		try{
			const apiResponse = await axios2.get('https://api.ipify.org/?format=json');
			loginInfo = apiResponse;
			if(apiResponse.data != undefined) {
				ipAddress = apiResponse.data.ip;
			} else {
				ipAddress = "{{auto}}";
			}
		}catch(err){
			console.log(err)
		}
		const browser = this.getBrowserName();
		Sentry.setExtra(null);
		return new Promise((resolve, reject) => {
			axios.post('/auth/microsoft-login',{
				uniqueId, 
				username,
				login_time: new Date(),
				client_ip: loginInfo?.data?.ip ?? null,
				browser:browser,
				domain_name: window.location.host
			}).then(response =>{
				//console.log('Google signin success', response);
				if(response.status === 201){
					reject(response.data.message)
				}
				else{
					this.setSession(response.data.data.token);
					response.data.data.user.token = response.data.data.token;
					window.localStorage.setItem('endUserLoginLogId', response.data.data.endUserLoginLogId)
					window.localStorage.setItem('LOGIN', Date.now().toString())
					window.localStorage.removeItem('LOGIN');
					axios.defaults.headers.common.Authorization = `Bearer ${response.data.data.token}`;
					const user = {data:response.data.data.user,role:[response.data.data.user.roles_end_user.display_name]};
					Sentry.configureScope(function (scope) {
						scope.setLevel("error");
					});
					Sentry.setTag("email", response.data.data.user.email);
					Sentry.setTag("displayName", response.data.data.user.displayName);
					Sentry.setUser({
						"ip_address": ipAddress
					})
					if(user){
						resolve(user);
					}	
					else{
						reject(response.data.data.mesage)
					}
				}
				
					
			}).catch(err=>{
				console.log(err);
			})
		});
	}
	signInWithToken = async () => {
		return new Promise((resolve, reject) => {
			axios
				.post('/auth/access-token', {
				},
				{
					headers: {
					Authorization: `Bearer ${this.getAccessToken()}`
					}
				})
				.then(response => {
					if (response.data.data.user) {
						this.setSession(response.data.data.token);
						const user = {data:response.data.data.user,role:[response.data.data.user?.roles_end_user?.display_name]};
						resolve(user);
					} else {
						this.logout();
						reject(new Error('Failed to login with token.'));
					}
				})
				.catch(error => {
					this.logout();
					reject(new Error('Failed to login with token.'));
				});
		});
	};

	updateUserData = user => {
		return axios.post('/api/auth/user/update', {
			user
		});
	};



	setSession = access_token => {
		if (access_token) {
			// localStorage.setItem('jwt_access_token', access_token);
			axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
			const expires = new Date();
			expires.setDate(Date.now() + 1000 * 60 * 60);
			// cookie.save('token', access_token, { path: '/',expires });
			window.localStorage.setItem('token', access_token)
			
		} else {
			localStorage.removeItem('jwt_access_token');
			delete axios.defaults.headers.common.Authorization;
		}
	};

	logout = (autoLogout=false) => {
		// const dateString = new Date().toISOString()
		// const timeString = new Date().toTimeString()
		// const date = dateString.split("T")[0].split("-")
		// const time = timeString.split(" ")

		axios.post('/auth/set-session-info-on-logout',{
			rowId: window.localStorage.getItem('endUserLoginLogId'),
			autoLogout: autoLogout,
			// logout_time: `${date[1]}-${date[2]}-${date[0]} ${time[0]}`,
		}).then(()=>{

		}).catch((err)=>{

		})
		// persistor.pause();
		localStorage.removeItem('jwt_access_token');
		this.setSession(null);
		sessionStorage.clear();
		// cookie.remove('token', { path: '/' });
		window.localStorage.removeItem('endUserLoginLogId');
		window.localStorage.removeItem('token')
		window.localStorage.setItem('LOGOUT', Date.now().toString())
		window.localStorage.removeItem('LOGOUT');
		window.sessionStorage.removeItem('msal.idtoken')
		// window.sessionStorage
		Object.keys(sessionStorage).forEach(key => {
			if(key.startsWith("msal.")) sessionStorage.removeItem(key);
		})
	};

	isAuthTokenValid = access_token => {
		if (!access_token) {
			return false;
		}
		const decoded = jwtDecode(access_token);
		const currentTime = Date.now() / 1000;
		if (decoded.exp < currentTime) {
			console.warn('access token expired');
			return false;
		}

		return true;
	};

	getAccessToken = () => {
		let access_token = window.localStorage.getItem('jwt_access_token');
		if(!access_token){
			// if(axios.defaults.headers.common.Authorization){
			// 	access_token = axios.defaults.headers.common.Authorization.replace('Bearer ','');
			// }else{
				// access_token = cookie.load('token');
				access_token = window.localStorage.getItem('token')
			// }
		}
		return access_token;
	};
}

const instance = new JwtService();

export default instance;
