import React, { useEffect, useState } from "react";
import { Link, Redirect } from 'react-router-dom';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import {
  Button,
  Grid,
  Card,
  Typography,
  Box,
  Tabs,
  Tab,
  Skeleton,
  Tooltip
} from "@mui/material";
import Icon from "@material-ui/core/Icon";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import SubscriptionDetails from "./Tabs/contract_details";
import Payments from "./Tabs/Payments";
import PaymentMethod from "./Tabs/PaymentMethod";
import Incidents from "./Tabs/Incidents";
import Activities from "./Tabs/Activities";
import ServiceRequests from "./Tabs/service_requests";
import Assets from "./Tabs/Assets";
import Files from "./Tabs/Files";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "styles/ag-grid-header-style.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import axios from "@fuse/utils/axios";
import { generateProperLocationString } from "@fuse/utils/general";
import { useRouter } from "@fuse/hooks";
import { dateReformatTo_mmm_dd_yyyy, getFormattedDate, monthDiff } from '@fuse/utils/dateOperations'

const rowData = [
  {
    make: "Toyota",
    model: "Celica",
    price: 35000,
    makesss: "Toyota",
    modellll: "Celica",
    pricepopop: 35000,
  },
  {
    make: "Toyota",
    model: "Celica",
    price: 35000,
    makesss: "Toyota",
    modellll: "Celica",
    pricepopop: 35000,
  },
  {
    make: "Toyota",
    model: "Celica",
    price: 35000,
    makesss: "Toyota",
    modellll: "Celica",
    pricepopop: 35000,
  },
  {
    make: "Toyota",
    model: "Celica",
    price: 35000,
    makesss: "Toyota",
    modellll: "Celica",
    pricepopop: 35000,
  },
  {
    make: "Toyota",
    model: "Celica",
    price: 35000,
    makesss: "Toyota",
    modellll: "Celica",
    pricepopop: 35000,
  },
  {
    make: "Toyota",
    model: "Celica",
    price: 35000,
    makesss: "Toyota",
    modellll: "Celica",
    pricepopop: 35000,
  },
  {
    make: "Toyota",
    model: "Celica",
    price: 35000,
    makesss: "Toyota",
    modellll: "Celica",
    pricepopop: 35000,
  },
];

const ContractDetailsDailog = ({
  fullWidth,
  maxWidth,
  open,
  setOpen,
  Close,
  classes,
  TabButton,
  tabState,
  setTabState,
  TabContainer,
  // contract_id,
}) => {
  const router = useRouter()
  const [contractDetails, setContractDetails] = useState({});
  const [contractProviderDetails, setContractProviderDetails] = useState({});
  const [shippingDetails, setShippingDetails] = useState({});
  const [paymentDetails, setPaymentDetails] = useState({});
  const [ billingCycleDetails, setBillingCycleDetails ] = useState(null);
  const [ paymentMethod, setPaymentMethod ] = useState(null);
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ contractFiles, setContractFiles ] = useState([])
  const [ isShareLinkCopied, setIsShareLinkCopied ] = useState(false)
  const contract_id = router.params.id

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        <Box sx={{ p: 3 }}>{children}</Box>
      </div>
    );
  }

  const setContractCurrentStatus = () => {
    if (!contractDetails?.contractEndDate) return;
    /*
     * Active- Contract End Date greater than 90 days
     * Expiring- Contract End Date in between 90 days
     * Expired - Contract End Date less than 90 days
    */
    if(Math.ceil((new Date(contractDetails?.contractEndDate).getTime() - new Date().getTime())/(1000*3600*24)) > 90){
      return { status: "Active", background: "green", icon: "done" };
    }else if(Math.ceil((new Date(contractDetails?.contractEndDate).getTime() - new Date().getTime())/(1000*3600*24)) <= 90 
      && Math.ceil((new Date(contractDetails?.contractEndDate).getTime() - new Date().getTime())/(1000*3600*24)) > 0){
      return { status: "Expiring", background: "grey", icon: "close" };
    }else if(Math.ceil((new Date(contractDetails?.contractEndDate).getTime() - new Date().getTime())/(1000*3600*24)) <= 0 ){
      return { status: "Expired", background: "grey", icon: "close" };
    }
  };

  const generateLocationName = (data) => {
    if (!data) return;
    const { address1, city, state, zip, country } = data;
    if (!address1 && !city && !state && !zip && !country) {
      return "-";
    }
    return `${data?.location_name ? data?.location_name + ", " : ""}${
      address1 ? address1 + ", " : ""
    }${city ? city + ", " : ""}${state ? state + ", " : ""}${
      zip ? zip + ", " : ""
    }${country ? country : ""}`;
  };

  const getContractDetails = () => {
    setLoading(true);
    axios.get(`/contracts/${contract_id}?archived=true`).then((res) => {
      let contractData = res.data.data.contract;
      console.log(contractData)
      let assetData = res.data.data.assets;
      setContractDetails({
        contractName: contractData.contract_name,
        contractNumber: contractData.entity_external_platform_id,
        fullLengthContractNumber: contractData.entity_external_platform_id,
        id: contractData.id,
        sla: contractData?.service_level_agreement?.sla_name,
        total_contract_value: contractData.total_contract_value,
        contractStatus:
          contractData?.contract_header_status?.contract_status_name,
        contractStatusColor: contractData?.contract_header_status?.status_color,
        contractType: contractData?.contract_headers_type?.display_name,
        contractStartDate: contractData?.contract_start_date,
        contractEndDate: contractData?.contract_end_date,
        contractDescription: contractData?.contract_description,
        numberOfAssets: assetData.totalAssets,
        master: contractData.master === false ? "No" : "Yes",
        parentContract:
          contractData?.parent_contract?.entity_external_platform_id,
      });
      setAssets(!assetData?.listing ? [] : flattenAssets(assetData?.listing));
      setContractProviderDetails(contractData?.contract_service_provider);
      setPaymentDetails(contractData?.contract_payment);
      setShippingDetails(contractData?.shipping);
      setBillingCycleDetails(contractData?.subscription_billing);
      setPaymentMethod(contractData?.subscription_payment);
    }).catch((err) => {
      if(err?.response?.data?.message === "Invalid request params input" || 
        err?.response?.data?.message === "Contract not found"){
        router.push('/not-found')
      }
    }).finally(()=>{
      setLoading(false)
    })
  };

  const flattenAssets = (data) => {
    if (!data) return [];
    return data.map((item) => ({
      id: item?.id,
      serial_number: item?.serial_number,
      user_name: item?.assigned_user
        ? `${item?.assigned_user?.first_name} ${item?.assigned_user?.last_name}`
        : "Unassigned",
      model: item?.asset_model?.model_name,
      manufacturer: item?.asset_model?.manufacturer?.manufacturer_name,
      category: item?.asset_model?.category?.category_name,
      location: generateLocationName(item?.shipping),
      asset_status: item?.asset_status?.status_name,
    }));
  };

  function onFilterChanged(event) {
    const rows = event.api.getFilterModel();
    // validation for no-overlay
    const total_rows = event.api.getDisplayedRowCount();
    if (total_rows === 0) {
      event.api.showNoRowsOverlay();
    } else {
      event.api.hideOverlay();
    }
  }

  const setHeadersRow = (data) => {
    if (!data || data.length === 0) return [];
    return Object.keys(data[0]);
  };

  function fetchFiles(contract_id){
    if(!contract_id) return
    axios.get(`/contracts/get-contract-files/${contract_id}`).then((res)=>{
        const { files } = res.data.data;
        setContractFiles(files)
    }).catch((err)=>{   
        console.log(err);
    })
  }

  useEffect(() => {
    if (!contract_id) return setContractDetails({});
    getContractDetails();
    fetchFiles(contract_id)
  }, [contract_id]);

  function shareContract(contract_id) {
    let link = `${window.location.origin}/contracts/${contract_id}`
    navigator.clipboard.writeText(link);
    setIsShareLinkCopied(true)
  }

  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
      setValue(newValue)
  }

  return (
    <div style={{background: '#fff', marginBottom:'15px', padding: '28px'}}>
      <Link className="back_btn_icon" to="/contracts">
          <i className='ti ti-arrow-left' />
      </Link>
      <>
        <div className='inner_details_section'>
          <div className='flexbox_holder'>
            <div className='left_holder'>
              <div className='content_holder align-top pe-40'>  
                <div className='status_badge_holder'>
                  <span style={{
                      backgroundColor: setContractCurrentStatus()
                      ?.background
                  }} className='dot_holder' />                  
                  <span style={{ display: "inline-block", paddingLeft: '4px', textTransform: 'capitalize' }}>
                      {setContractCurrentStatus()?.status}
                  </span>
                </div>

                <div className='name_loc_info_holder'>
                  <div className='flex flex-wrap items-center'>
                    <Typography variant="h6" className='main_page_heading' component="div">
                      {contractDetails?.contractName ?? 'N/A'}
                    </Typography>
                    <span className='orange_badge_holder'>{contractDetails?.contractNumber ?? 'N/A'}</span>
                    <p className="desc_text">
                      {contractDetails.contractDescription === '' ? 'No Description' : contractDetails.contractDescription }
                    </p>
                  </div>
                </div>

                <div className='infoStat_card_holder'>
                  <div className='each_card'>
                    <p className='label_holder'>Service Provider</p>
                    <p className='name_holder'>{contractProviderDetails?.service_provider_name ?? 'N/A'}</p>                      
                  </div>
                  <div className='each_card'>
                    <p className='label_holder'>Contract Number</p>
                    <p className='name_holder'>{contractDetails?.contractNumber ?? 'N/A'}</p>                      
                  </div>
                  <div className='each_card'>
                    <p className='label_holder'>Contract Type</p>
                    <p className='name_holder'>{contractDetails?.contractType ?? 'N/A'}</p>                      
                  </div>
                  <div className='each_card'>
                    <p className='label_holder'>Number of Assets</p>
                    <p className='name_holder'>{contractDetails?.numberOfAssets ?? 'N/A'}</p>                      
                  </div>
                  <div className='each_card'>
                    <p className='label_holder'>Service Level Agreement(SLA)</p>
                    <p className='name_holder'>{contractDetails?.sla ?? 'N/A'}</p>                      
                  </div>
                  <div className='each_card'>
                    <p className='label_holder'>Start Date</p>
                    <p className='name_holder'>{contractDetails?.contractStartDate ? dateReformatTo_mmm_dd_yyyy(contractDetails.contractStartDate) : 'N/A'}</p>                      
                  </div>
                  <div className='each_card'>
                    <p className='label_holder'>End Date</p>
                    <p className='name_holder'>{contractDetails?.contractEndDate ? dateReformatTo_mmm_dd_yyyy(contractDetails.contractEndDate) : 'N/A'}</p>                      
                  </div>
                </div>
              </div>
                  {/* <Grid item lg={8}>                    
                    <Grid container spacing={1} style={{ marginBottom: "20px" }}>
                      <Grid item lg={6}>
                        <Typography
                          variant="subtitle1"
                          style={{ color: "#848D9E", fontWeight: "600" }}
                        >
                          {" "}
                          Contract Name
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          style={{
                            color: "#3A4252",
                            fontWeight: "400",
                            maxWidth: "350px",
                            wordBreak: "break-all",
                          }}
                        >
                          {contractDetails?.contractName}
                        </Typography>
                      </Grid>
                      <Grid item lg={6}>
                        <Grid container>
                          <Grid item lg={6}>
                            <Typography
                              variant="subtitle1"
                              style={{ color: "#848D9E", fontWeight: "600" }}
                            >
                              {" "}
                              Contract Number
                            </Typography>
                            <Tooltip id={contractDetails?.fullLengthContractNumber} title={contractDetails?.fullLengthContractNumber} placement="top">
                              <Typography
                                variant="subtitle1"
                                style={{ color: "#3A4252", fontWeight: "400" }}
                              >
                              
                                {contractDetails?.contractNumber}
                              
                            </Typography>
                            </Tooltip>
                          </Grid>
                          <Grid
                            item
                            lg={6}
                            className="flex flex-col justify-end items-center gap-2"
                          >
                            <div
                              variant="contained"
                              style={{
                                padding: "7px 11px",
                                width: "140px",
                                justifyContent: 'flex-start',
                                paddingLeft: '27px',
                                backgroundColor: setContractCurrentStatus()
                                  ?.background,
                              }}
                            >
                              <Icon>
                                <span class="material-symbols-outlined">
                                  {setContractCurrentStatus()?.icon}
                                </span>
                              </Icon>
                              <span>{setContractCurrentStatus()?.status}</span>
                            </div>
                            <Button
                              variant="contained"
                              style={{ background: isShareLinkCopied ? '#90EE90' : '#808080', width: "140px", justifyContent: 'flex-start', paddingLeft: '27px'}}
                              onClick={()=>shareContract(contract_id)}>
                            <Icon className="text-white mr-5" fontSize="default"><span className="material-symbols-outlined">{isShareLinkCopied ? 'file_copy' : 'share'}</span></Icon>
                            {isShareLinkCopied ? 'Copied' : 'Share'}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={6}>
                        <Typography
                          variant="subtitle1"
                          style={{ color: "#848D9E", fontWeight: "600" }}
                        >
                          {" "}
                          Number Of Assets
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          style={{ color: "#3A4252", fontWeight: "400" }}
                        >
                          {" "}
                          {contractDetails?.numberOfAssets}
                        </Typography>
                      </Grid>
                      <Grid item lg={6}>
                        <Typography
                          variant="subtitle1"
                          style={{ color: "#848D9E", fontWeight: "600" }}
                        >
                          {" "}
                          Service Level Agreement
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          style={{ color: "#3A4252", fontWeight: "400" }}
                        >
                          {contractDetails?.sla}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ background: "#F7F7F7" }}>
                      <Grid lg={12} item>
                        <Typography
                          variant="h6"
                          style={{
                            color: "#2F333D",
                            fontWeight: "600",
                            fontSize: "17px",
                          }}
                        >
                          Service Provider Information
                        </Typography>
                      </Grid>

                      <Grid item lg={6} style={{ marginBottom: "10px" }}>
                        <Typography
                          variant="subtitle1"
                          style={{ color: "#848D9E", fontWeight: "600" }}
                        >
                          {" "}
                          Name{" "}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          style={{ color: "#3A4252", fontWeight: "400" }}
                        >
                          {contractProviderDetails?.service_provider_name}
                        </Typography>
                      </Grid>

                      <Grid item lg={6} style={{ marginBottom: "10px" }}>
                        <Typography
                          variant="subtitle1"
                          style={{ color: "#848D9E", fontWeight: "600" }}
                        >
                          {" "}
                          Address{" "}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          style={{ color: "#3A4252", fontWeight: "400" }}
                        >
                          {generateLocationName(contractProviderDetails)}
                        </Typography>
                      </Grid>

                      <Grid item lg={6} style={{ marginBottom: "10px" }}>
                        <Typography
                          variant="subtitle1"
                          style={{ color: "#848D9E", fontWeight: "600" }}
                        >
                          {" "}
                          Website{" "}
                        </Typography>
                        <Link
                          variant="subtitle1"
                          style={{
                            color: "#3A4252",
                            fontWeight: "400",
                            cursor: "pointer",
                          }}
                          href={`https://${contractProviderDetails?.url}`}
                          target="_blank"
                        >
                          {contractProviderDetails?.url}
                        </Link>
                      </Grid>
                      <Grid item lg={6} style={{ marginBottom: "10px" }}>
                        <Typography
                          variant="subtitle1"
                          style={{ color: "#848D9E", fontWeight: "600" }}
                        >
                          {" "}
                          Phone
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          style={{ color: "#3A4252", fontWeight: "400" }}
                        >
                          {contractProviderDetails?.phone_number !== ""
                            ? contractProviderDetails?.phone_number
                            : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid> */}
            </div>

            <div style={{maxHeight: '100%'}} className='map_location_holder'>
              { loading ? 
                <>
                  <Skeleton width={318} height={260} style={{ transform: 'none' }} />
                </> : 
                <>
                  <p className='asset_address_text'>
                    <i style={{fontSize: '20px'}} className='ti ti-map-pin' />
                    {generateProperLocationString({
                      location_name: shippingDetails?.location_name ?? '',
                      address1: shippingDetails?.address1 ?? '',
                      address2: shippingDetails?.address2 ?? '',
                      city: shippingDetails?.city ?? '',
                      state: shippingDetails?.state ?? '',
                      country: shippingDetails?.country ?? ''
                    })}
                  </p>
                    {/* <div className="mt-5" style={{ display: "flex", alignItems: "center" }}>
                        <Icon className="text-red-400 mt-2 mr-5" fontSize="default">location_on_outlined</Icon>
                        <Typography variant='h6'>Location</Typography>
                    </div> */}
                        {/* <div className="m-5" style={{ alignItems: "center", flex: 1 }}>
                            <p className='asset-address-text' styl>{generateProperLocationString({
                                location_name: assetDetails?.shipping?.location_name ?? '',
                                address1: assetDetails?.shipping?.address1 ?? '',
                                address2: assetDetails?.shipping?.address2 ?? '',
                                city: assetDetails?.shipping?.city ?? '',
                                state: assetDetails?.shipping?.state ?? '',
                                country: assetDetails?.shipping?.country ?? ''
                            })}</p>
                        </div> */}
                        <div>
                          <iframe
                            width="100%"
                            height="260"
                            src={`https://maps.google.com/maps?q=${encodeURIComponent(
                              [
                                shippingDetails?.address1,
                                shippingDetails?.address2,
                                shippingDetails?.city,
                                shippingDetails?.state,
                                shippingDetails?.zip,
                                shippingDetails?.country,
                              ]
                                .filter((item) => item)
                                .join(",")
                            )}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
                            frameBorder="0"
                            scrolling="no"
                            marginHeight="0"
                            marginWidth="0"
                          ></iframe>
                        </div>
                </>
              }
            </div>

                {/* <Grid container spacing={1} style={{ background: "#fff" }}>
                  <div>
                    <Card
                      style={{
                        borderRadius: "0",
                        boxShadow: "none",
                        height: "100%",
                      }}
                    >
                      <CardActions
                        disableSpacing
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          borderRadius: "0",
                          boxShadow: "none",
                        }}
                      >
                        <TabButton
                          tab="details"
                          tabState={tabState}
                          tabStateChange={setTabState}
                          activeClass={classes.activeTab}
                          variant="text"
                          color="primary"
                          className={`w-full d-flex justify-content-start ${classes.tabButton}`}
                          style={{
                            width: "100%",
                            margin: "5px 0",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          Details
                        </TabButton>
                        <TabButton
                          tab="assets"
                          tabState={tabState}
                          tabStateChange={setTabState}
                          activeClass={classes.activeTab}
                          variant="text"
                          color="primary"
                          className={`w-full d-flex justify-content-start ${classes.tabButton}`}
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "start",
                            margin: "5px 0",
                          }}
                        >
                          Assets ({contractDetails?.numberOfAssets})
                        </TabButton>
                        <TabButton
                          tab="payment"
                          tabState={tabState}
                          tabStateChange={setTabState}
                          activeClass={classes.activeTab}
                          variant="text"
                          color="primary"
                          className={`w-full d-flex justify-content-start ${classes.tabButton}`}
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "start",
                            margin: "5px 0",
                          }}
                        >
                          Billing Cycle
                        </TabButton>
                        <TabButton
                          tab="paymentMethod"
                          tabState={tabState}
                          tabStateChange={setTabState}
                          activeClass={classes.activeTab}
                          variant="text"
                          color="primary"
                          className={`w-full d-flex justify-content-start ${classes.tabButton}`}
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "start",
                            margin: "5px 0",
                          }}
                        >
                          Payment Method
                        </TabButton>
                        <TabButton
                          tab="incidents"
                          tabState={tabState}
                          tabStateChange={setTabState}
                          activeClass={classes.activeTab}
                          variant="text"
                          color="primary"
                          className={`w-full d-flex justify-content-start ${classes.tabButton}`}
                          style={{
                            width: "100%",
                            margin: "5px 0",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          Insights
                        </TabButton>
                        <TabButton
                          tab="service_tickets"
                          tabState={tabState}
                          tabStateChange={setTabState}
                          activeClass={classes.activeTab}
                          variant="text"
                          color="primary"
                          className={`w-full d-flex justify-content-start ${classes.tabButton}`}
                          style={{
                            width: "100%",
                            margin: "5px 0",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          Service Ticketsss
                        </TabButton>
                        <TabButton
                          tab="files"
                          tabState={tabState}
                          tabStateChange={setTabState}
                          activeClass={classes.activeTab}
                          variant="text"
                          color="primary"
                          className={`w-full d-flex justify-content-start ${classes.tabButton}`}
                          style={{
                            width: "100%",
                            margin: "5px 0",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          Files ({contractFiles.length})
                        </TabButton>
                        <TabButton
                          tab="activity"
                          tabState={tabState}
                          tabStateChange={setTabState}
                          activeClass={classes.activeTab}
                          variant="text"
                          color="primary"
                          className={`w-full d-flex justify-content-start ${classes.tabButton}`}
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "start",
                            margin: "5px 0",
                          }}
                        >
                          Activities
                        </TabButton>
                      </CardActions>
                    </Card>
                  </div>
                  <div className={classes.cardGen} style={{ width: "80%" }}>
                    <Card
                      className={classes.card}
                      style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: "0",
                        overflow: "auto",
                      }}
                    >
                      <CardContent
                        className={classes.cardContainer}
                        style={{ width: "100%" }}
                      >
                        <TabContainer tab="details" tabState={tabState}>
                          <SubscriptionDetails contractDetails={contractDetails} />
                        </TabContainer>

                        <TabContainer tab="assets" tabState={tabState}> */}
                          {/* <div
                            className="ag-theme-alpine"
                            style={{
                              height: "250px",
                              width: "100%",
                              overflow: "auto",
                            }}
                          >
                            {contractDetails?.numberOfAssets === 0 ? (
                              <Typography
                                style={{ textAlign: "center", margin: "30px 0" }}
                              >
                                No Rows to Showvfsdvz!
                              </Typography>
                            ) : (
                              <AgGridReact
                                rowData={assets}
                                // get filter model
                                onFilterChanged={onFilterChanged}
                                // no rows overlay
                                overlayNoRowsTemplate={
                                  "<span>No Result Found</span>"
                                }
                              >
                                {setHeadersRow(assets).map((header, index) => {
                                  return (
                                    <AgGridColumn
                                      field={header}
                                      key={index}
                                      headerName={header
                                        .split("_")
                                        .map(
                                          (word) =>
                                            word.charAt(0).toUpperCase() +
                                            word.substring(1)
                                        )
                                        .join(" ")}
                                      sortable={true}
                                      filter="text"
                                      floatingFilter={true}
                                    ></AgGridColumn>
                                  );
                                })}
                              </AgGridReact>
                            )}
                          </div> */}
                          {/* <Assets 
                            allAssets={assets}
                            getContractDetails={getContractDetails}
                            contractDetails={contractDetails}
                          />
                        </TabContainer>

                        <TabContainer tab="payment" tabState={tabState}>
                          <Payments billingCycleData={billingCycleDetails} />
                        </TabContainer>

                        <TabContainer tab="paymentMethod" tabState={tabState}>
                          <PaymentMethod paymentMethodData={paymentMethod} />
                        </TabContainer>

                        <TabContainer tab="service_tickets" tabState={tabState}>
                          <ServiceRequests allAssets={assets} />
                        </TabContainer>

                        <TabContainer tab="incidents" tabState={tabState}>
                          <Incidents contract_id={contractDetails.id}/>
                        </TabContainer>
                        
                        <TabContainer tab="files" tabState={tabState}>
                          <Files
                            contract_id={contractDetails.id}
                            contractFiles={contractFiles}
                            fetchFiles={fetchFiles}
                          />
                        </TabContainer>

                        <TabContainer tab="activity" tabState={tabState}>
                          <Activities contract_id={contractDetails.id} />
                        </TabContainer>
                      </CardContent>
                    </Card>
                  </div>
                </Grid> */}
          {/* </DialogContent> */}
          {/* <DialogActions>
            <Button onClick={Close} variant="contained" color="primary">
              Close
            </Button>
          </DialogActions> */}
          </div>
        </div>

        <div style={{paddingTop: '56px'}}>
          <Box sx={{ display: 'inline-block', backgroundColor: '#F2F4F7', padding: '8px', borderRadius: '8px' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="category_tabs">
              <Tab className='tabs_exampleText' label={`Assets (${contractDetails?.numberOfAssets})`} />
              <Tab className='tabs_exampleText' label="Billing Cycle" />
              <Tab className='tabs_exampleText' label="Payment Method" />
              <Tab className='tabs_exampleText' label="Insights" />
              <Tab className='tabs_exampleText' label="Service Tickets" />
              <Tab className='tabs_exampleText' label={`Files (${contractFiles.length})`} />
              <Tab className='tabs_exampleText' label="Activities" />
            </Tabs>
          </Box>  

          <div className='tab_scrollContent'>
            {value === 0 && <CustomTabPanel className='custom_tab' id='tab-0'>
              <Assets 
                allAssets={assets}
                getContractDetails={getContractDetails}
                contractDetails={contractDetails}
              />
            </CustomTabPanel>}
            {value === 1 && <CustomTabPanel className='custom_tab' id='tab-0'>
              <Payments billingCycleData={billingCycleDetails} paymentMethodData={paymentMethod}  />
            </CustomTabPanel>}
            {value === 2 && <CustomTabPanel className='custom_tab' id='tab-0'>
              <PaymentMethod paymentMethodData={paymentMethod} />
            </CustomTabPanel>}
            {value === 3 && <CustomTabPanel className='custom_tab' id='tab-0'>
              <Incidents contract_id={contractDetails.id}/>
            </CustomTabPanel>}
            {value === 4 && <CustomTabPanel className='custom_tab' id='tab-0'>
              <ServiceRequests allAssets={assets} />
            </CustomTabPanel>}
            {value === 5 && <CustomTabPanel className='custom_tab' id='tab-0'>
              <Files
                contract_id={contractDetails.id}
                contractFiles={contractFiles}
                fetchFiles={fetchFiles}
              />
            </CustomTabPanel>}
            {value === 6 && <CustomTabPanel className='custom_tab' id='tab-0'>
              <Activities contract_id={contractDetails.id} />
            </CustomTabPanel>}
          </div>
        </div>
      </>
    </div>
  );
};

export default ContractDetailsDailog;
